import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {getActivityList} from '../../store/actions/activityActions';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import {prettyFloatMoney} from '../../helpers/utils';
import Preloader from '../../components/UI/Preloader';
import {RoundButton} from '../../components/UI/RoundButton';
import './index.scss'

class ActivityModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20
    }
  }

  componentDidMount() {
    this.props.getActivityList(this.state);
    this.props.getMissingProfileDetails();
  }

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1
      this.props.getActivityList({
        ...this.state,
        page: nextPage,
      }, true);

      return this.setState({ ...this.state, page: nextPage })
    }
  }

  render() {
    const { page } = this.state;
    const { data, loading } = this.props.activityList;
    const isEmpty = !(data && data.list.length);

    return (
      <div className="activity-module">
        <div className="container-inner">
          <h1 className="activity-module__title f-42 f-500">Activity</h1>
          {(loading && !data)
            ? <Preloader />
            : (isEmpty ? (
              <div className="activity-module__empty f-16 f-400">No activity yet.</div>
            ) : (
              <div className="activity-module__table">
                <div className="activity-module__thead">
                  <div className="activity-module__thead-col">Date</div>
                  <div className="activity-module__thead-col">Details</div>
                  <div className="activity-module__thead-col">Amount</div>
                </div>
                <div className="activity-module__tbody">
                  {data && data.list.map((item) => (
                    <div className="activity-module__tbody-row" key={item.id}>
                      <div className="activity-module__tbody-col">{moment(item.created_at).format('DD MMMM YYYY, HH:mm')}</div>
                      <div className="activity-module__tbody-col">{item.details}</div>
                      <div className="activity-module__tbody-col"><p className="tl">{prettyFloatMoney(item.amount)}</p></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {data && page < data.total_pages && (
            <RoundButton
              type="button"
              label="Show more"
              className="activity-module__more-btn"
              fullWidth
              onClick={() => this.getNext(data.total_pages)}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activityList: state.activityStore.activityList,
})

const mapDispatchToProps = dispatch => ({
  getActivityList: (params, isNext) => dispatch(getActivityList(params, isNext)),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModule);