import React from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import logoWhite from '../../assets/images/logo_white.svg';
import {MyLink} from '../MyLink';
import {APP_LINKS} from '../../helpers/links';
import {CONTACT_EMAIL} from "../../helpers/constants";
import './index.scss';

const Footer = () => {
  const location = useLocation();
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className="footer">
      <div className={
        location
          && location.pathname === '/sign-in'
          || location.pathname === '/sign-up'
          || location.pathname === '/sign-up/fds'
          || location.pathname === '/forgot-password'
          ? 'container'
          : 'container-inner'
      }
      >
        <div className="footer__inner">
          <nav>
            {
                location
                  && location.pathname === '/sign-in'
                  || location.pathname === '/sign-up/'
                  || location.pathname === '/sign-up/fds'
                  || location.pathname === '/forgot-password'
                  ? (
                    <ul>
                      <li className="footer_logo">
                        <MyLink className="footer__logo" href={APP_LINKS.dashboard}>
                          <img src={logoWhite} alt="logo_white" />
                        </MyLink>
                      </li>
                    </ul>
                  )
                  : null
              }
            <ul>
              <h4 className="f-20 f-500">Company</h4>
              <li>
                <MyLink href={APP_LINKS.founderPromoDomain} isExternal>Home</MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Legal</h4>
              <li>
                <MyLink href={APP_LINKS.docPrivacyAndPolicy} isExternal>
                  Privacy Policy
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docTermsOfUse} isExternal>
                  Terms of Use
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>
                  Financing Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>
                  Subordinated Financing Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.suretyAgreement} isExternal>
                  Surety agreement
                </MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Help</h4>
              {/*<li>*/}
              {/*  <a href="/help">FAQ</a>*/}
              {/*</li>*/}
              <li>
                <MyLink href={APP_LINKS.helpLoanTerms}>Loan terms</MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Support</h4>
              <p>Contact us:</p>
              <li>
                <MyLink href={`mailto:${CONTACT_EMAIL}`} isExternal>{CONTACT_EMAIL}</MyLink>
              </li>
              <li>
                <div className="footer__content-social">
                  <MyLink isExternal href={config.facebookLink}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32 16C32 7.1625 24.8375 0 16 0C7.1625 0 0 7.1625 0 16C0 23.9875 5.85 30.6062 13.5 31.8062V20.625H9.4375V16H13.5V12.475C13.5 8.46563 15.8875 6.25 19.5438 6.25C21.2938 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1063C19.1188 10.5 18.5 11.7344 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8062C26.15 30.6062 32 23.9875 32 16Z" fill="#757575" />
                    </svg>
                  </MyLink>

                  <MyLink isExternal href={config.linkedinLink}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM7.68108 13.2519H11.307V24.1464H7.68108V13.2519ZM11.5458 9.8818C11.5223 8.8136 10.7584 8 9.51802 8C8.2776 8 7.46666 8.8136 7.46666 9.8818C7.46666 10.9279 8.25363 11.7649 9.47095 11.7649H9.49412C10.7584 11.7649 11.5458 10.9279 11.5458 9.8818ZM20.2088 12.9961C22.5948 12.9961 24.3836 14.5535 24.3836 17.8998L24.3834 24.1464H20.7576V18.3178C20.7576 16.8538 20.2329 15.8548 18.9203 15.8548C17.9186 15.8548 17.3219 16.5283 17.0599 17.1788C16.964 17.4119 16.9404 17.7367 16.9404 18.0623V24.1467H13.3141C13.3141 24.1467 13.3619 14.2745 13.3141 13.2522H16.9404V14.7953C17.4217 14.0535 18.2836 12.9961 20.2088 12.9961Z" fill="#757575" />
                    </svg>
                  </MyLink>

                  <MyLink isExternal href={config.instagramLink}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM7.68108 13.2519H11.307V24.1464H7.68108V13.2519ZM11.5458 9.8818C11.5223 8.8136 10.7584 8 9.51802 8C8.2776 8 7.46666 8.8136 7.46666 9.8818C7.46666 10.9279 8.25363 11.7649 9.47095 11.7649H9.49412C10.7584 11.7649 11.5458 10.9279 11.5458 9.8818ZM20.2088 12.9961C22.5948 12.9961 24.3836 14.5535 24.3836 17.8998L24.3834 24.1464H20.7576V18.3178C20.7576 16.8538 20.2329 15.8548 18.9203 15.8548C17.9186 15.8548 17.3219 16.5283 17.0599 17.1788C16.964 17.4119 16.9404 17.7367 16.9404 18.0623V24.1467H13.3141C13.3141 24.1467 13.3619 14.2745 13.3141 13.2522H16.9404V14.7953C17.4217 14.0535 18.2836 12.9961 20.2088 12.9961Z" fill="#757575" />
                    </svg>
                  </MyLink>
                </div>
              </li>
            </ul>
          </nav>

          <hr />

          <div className="footer__content-copyright">
            <p>
              ©
              {currentYear}
              {' '}
              Scramble OÜ. All rights reserved.
            </p>
            <p>Scramble OÜ (registry code: 14991448) Pärnu mnt 22, 10141 Tallinn, Estonia</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
