import React  from 'react';
import FundraisingInfo from '../../FundraisingInfo';
import NotificationBlock from '../../NotificationBlock';
import {NOTIFICATIONS} from "../../../components/Notification/list";
import './index.scss';

// import FundraisingOnHoldFAQ from '../../../components/FAQ/FundraisingOnHoldFAQ';
// import HoldCustomPartnersList from './custom';

const FundraisingOnHold = () => {
  const notifications = [NOTIFICATIONS.round_results_being_processed()];

  return (
    <div className="fundraising-on-hold">
      <h1 className="f-42 f-500">Get ready for the results</h1>
      <NotificationBlock
        notifications={notifications}
        className="fundraising-on-hold__notifications"
      />
      <FundraisingInfo className="fundraising-on-hold__dashboard" />
    </div>
  );
}

export default FundraisingOnHold;
