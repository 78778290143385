import React, { useEffect, useState } from 'react';
import qs from 'qs';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { APP_LINKS } from '../../../helpers/links';
import { prettyFloatMoney, scrollTop } from '../../../helpers/utils';
import WarningBlock from '../../../components/UI/WarningBlock';
import { RoundLink } from '../../../components/UI/RoundLink';
import { getStripePaymentInfo } from '../../../store/services/stripeServices';
import { STRIPE_PAYMENT_TYPES } from '../../../components/Forms/PaymentForm/PaymentMethod';
import s from './index.module.scss';

const message = {
  [STRIPE_PAYMENT_TYPES.card]: ({ net_amount }) => (
    <div className="f-16 f-400">
      Your payment of{' '}
      <span className="f-500">{prettyFloatMoney(net_amount)}</span> was
      successfully transferred to your Scramble account.
    </div>
  ),
  [STRIPE_PAYMENT_TYPES.sepa_debit]: ({ net_amount, amount }) => (
    <div className="f-16 f-400">
      Your payment of{' '}
      <span className="f-500">
        {prettyFloatMoney(net_amount || amount - 0.35)}
      </span>{' '}
      is in process, so funds are not immediately available. A charge created
      from a SEPA Direct Debit source can remain in a pending state for up to 14
      business days from its creation, though the average time is around five
      business days. Once the charge is confirmed, payment will be transferred
      to your Scramble account.
    </div>
  ),
};

export const PaymentSuccess = () => {
  const location = useLocation();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    getPaymentInfo();
  });

  const getPaymentInfo = async () => {
    const { session_id } = qs.parse(location.search.replace('?', ''));
    if (session_id) {
      const res = await getStripePaymentInfo(session_id);
      res && res.success && setInfo(res.data);
    }
  };

  if (!info) {
    return null;
  }

  const { payment_method } = info;

  return (
    <div className={s.wrapper}>
      <h1 className={classnames(s.title, 'f-26 f-500')}>Payment successful!</h1>
      <WarningBlock withBorder className={s.message} isSuccess>
        {message[payment_method || 'card'](info)}
      </WarningBlock>

      <p className={classnames(s.desc, 'f-16 f-500')}>
        Congratulations!
      </p>

      <div className={s.buttons}>
        <RoundLink
          path={APP_LINKS.fundraising}
          label="Go to fundraising"
          fillBackground
          className={s.button}
        />
        <RoundLink
          path={APP_LINKS.addFunds}
          label="Add more cash"
          className={s.button}
        />
      </div>
    </div>
  );
};
