import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const RadioButton = ({ value, onChange, checked, name="radio", className, children, disabled }) => {
  return (
    <label className={classnames("radio-button", className)}>{children || value}
      <input value={value} type="radio" checked={checked} name={name} onChange={onChange} disabled={disabled} />
      <span className="radio-button__checkmark" />
    </label>
  );
};

export default RadioButton;