export const EXAMPLE_DATA_6 = {
  total: 10600,
  total_fee: 600,
  total_principal: 10000,
  list: [
    {
      date: '2022-01-05',
      fee: 600,
      principal: 0,
      total: 600,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-07-05',
      fee: 0,
      principal: 8000,
      total: 8000,
    }
  ]
}

export const EXAMPLE_DATA_12 = {
  total: 11200,
  total_fee: 1200,
  total_principal: 10000,
  list: [
    {
      date: '2022-01-05',
      fee: 600,
      principal: 0,
      total: 600,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-07-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-01-05',
      fee: 0,
      principal: 5600,
      total: 5600,
    },
  ]
}

export const EXAMPLE_DATA_18 = {
  total: 11800,
  total_fee: 1800,
  total_principal: 10000,
  list: [
    {
      date: '2022-01-05',
      fee: 600,
      principal: 0,
      total: 600,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-07-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-01-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-07-05',
      fee: 0,
      principal: 3200,
      total: 3200,
    }
  ]
}

export const EXAMPLE_DATA_24 = {
  total: 12400,
  total_fee: 2400,
  total_principal: 10000,
  list: [
    {
      date: '2022-01-05',
      fee: 600,
      principal: 0,
      total: 600,
    },
    {
      date: '2022-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-07-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2022-08-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-09-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-10-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-11-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2022-12-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-01-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2023-02-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-03-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-04-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-05-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-06-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-07-05',
      fee: 600,
      principal: 400,
      total: 1000,
    },
    {
      date: '2023-08-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-09-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-10-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-11-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2023-12-05',
      fee: 0,
      principal: 400,
      total: 400,
    },
    {
      date: '2024-01-05',
      fee: 0,
      principal: 800,
      total: 800,
    }
  ]
}