import React from 'react';
import withScroll from '../../hoc/withScroll';
import LoanRepayments from '../../assets/images/loan_repayments.png';
import './index.scss'

const LoanTermsOldPage = () => (
  <div className="loan_terms_old_page">
    <div className="container-inner">
      <h1 className="f-42 f-500">Loan terms</h1>
      <h2 className="f-26 f-500">Loan pricing</h2>
      <p className="loan_terms_old_page__desc f-16 f-400">Price of loans consists of the following components</p>
      <div className="loan_terms_old_page__list">
        <div className="loan_terms_old_page__list-item">
          <p className="f-20 f-500">6% Annual interest rate</p>
          <p className="f-16 f-400">Annual interest paid monthly on outstanding loan principal.</p>
          <p className="f-16 f-400">Same annual interest rate applies if startup is successful or in case startup failed and the loan is repaid by the founders personally.</p>
        </div>
        <div className="loan_terms_old_page__list-item">
          <p className="f-20 f-500">9% Success fee</p>
          <p className="f-16 f-400">Paid only in case startup survives and is able to repay the loan principal.</p>
          <p className="f-16 f-400">We’ll charge a success fee upon loan principal repayment.</p>
        </div>
        <div className="loan_terms_old_page__list-item">
          <p className="f-20 f-500">5% Security deposit (refundable)</p>
          <p className="f-16 f-400">Security deposit is held by Scramble. These funds are used to repay investors in case of founder death/disability, or in case of founder fraud.</p>
          <p className="f-16 f-400">Once loans are repaid, unused security deposits are refunded to businesses.</p>
        </div>
      </div>

      <h2 className="f-26 f-500">Loan repayments</h2>
      <p className="loan_terms_old_page__desc f-16 f-400">Regular repayment scenario:</p>
      <img src={LoanRepayments} alt="Loan repayments" className="loan_terms_old_page__image" />

      <div className="loan_terms_old_page__list">
        <div className="loan_terms_old_page__list-item">
          <p className="f-20 f-500">From 1 to 6 months</p>
          <p className="f-16 f-400">During the first 6 months you only make monthly interest payments (6% annual interest means 0.5% of remaining principal each month).</p>
        </div>
        <div className="loan_terms_old_page__list-item">
          <p className="f-20 f-500">From 7 to 12 months</p>
          <p className="f-16 f-400">During months 7th to 12th you repay the 6% annual interest, loan principal in parts, and corresponding portion of the success fee.</p>
        </div>
      </div>
      <div className="loan_terms_old_page__notes">
        <p className="f-16 f-400">In a regular repayment scenario your total overpayment for the loan is <b>13.9%</b> (4.9% in annual interest and 9% success fee).</p>
        <p className="f-16 f-400">You can prepay up to 100% of the loan without any extra fees or penalties.</p>
        <p className="f-16 f-400">Scramble loans quickly become expensive if you do not maintain the repayment schedule. We charge a 3% of the loan principal penalty for each missed monthly repayment.</p>
        <p className="f-16 f-400">Late fees are only applied to the startup, and waived in case of a startup failure. So you don’t need to pay late fees in case you’re repaying the loan as a person.</p>
        <p className="f-16 f-400">If necessary, overdue loans can be refinanced on Scramble platform.</p>
      </div>
    </div>
  </div>
);

export default withScroll(LoanTermsOldPage);