import * as React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import {contactInfoUpdate, getUserLocation, registerUser} from '../../store/actions/userActions';
import {uploadFile, uploadPhoto} from '../../store/actions/commonActions';
import {getUserProfile} from '../../store/actions/profileActions';
import RegisterForm from '../../components/Forms/RegisterForm';
import VerifyForm from '../../components/VerifyForm'
import {cropAvatar} from '../../components/AvatarEdit';
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import {ROLES} from '../../helpers/constants';
import {validateCoFounderInviteCode} from '../../store/services/userServices';
import Preloader from '../../components/UI/Preloader';
import './index.scss';

class SignUpCoFounder extends React.Component {
  constructor(props) {
    super(props);
    this.totalSteps = 3;
    this.company = null;
    const {user, profile, location, match, history} = props;
    const {step} = qs.parse(location.search.replace('?', ''));
    this.inviteCode = match.params.inviteCode;
    if (user && !isNaN(step) && step > 1 && step <= this.totalSteps) {
      this.state = {
        step: parseInt(step),
        isValidating: true,
        error: ''
      }
    } else {
      this.state = {
        step: profile ? 2 : 1,
        isValidating: true,
        error: ''
      }
      location.search && history.replace(`/sign-up/co-founder/${this.inviteCode}`);
    }
  }

  componentDidMount() {
    this.validateCode();
    this.props.user && this.props.getUserProfile();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.setState(prevState => ({...prevState, step: 2}));
    }
  }

  validateCode = async () => {
    try {
      const res = await validateCoFounderInviteCode(this.inviteCode);
      if (res && res.success) {
        this.props.getUserLocation();
      }
      if (res && res.message) {
        this.setState(prevState => ({...prevState, error: res.message}));
      }
    } catch (e) {
      // Do nothing
    } finally {
      this.setState(prevState => ({...prevState,  isValidating: false}));
    }
  }

  nextStep = (extraState = {}) => this.state.step < this.totalSteps && this.setState(prevState => ({step: prevState.step + 1, ...extraState}))

  onRegister = async (data, {setSubmitting}) => {
    const payload = {
      email: data.email,
      password: data.password,
      invite_code: this.inviteCode || null,
    }
    const res = await this.props.registerUser(payload);
    setSubmitting(false);
    if (res && res.success) {
      this.company = res.data.user.company;
      this.nextStep();
    }
  }

  onContactInfoSubmit = async (data, {setSubmitting}) => {
    try {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        citizenship: data.citizenship,
        citizenship_code: data.citizenship_code,
        company_name: data.company_name,
        company_description: data.description,
        description: data.about,
        cv: null,
        photo: null
      };

      if (data.photo) {
        if (!data.photo.id && data.editorRef) {
          const croppedAvatar = cropAvatar(data.editorRef);
          const res = await this.props.uploadPhoto(croppedAvatar);
          res && res.id && (payload.photo = res.id);
        }

        if (data.photo.id) {
          payload.photo = data.photo.id;
        }
      }

      if (data.resume) {
        if (!data.resume.id) {
          const res = await this.props.uploadFile(data.resume);
          res && res.id && (payload.cv = res.id);
        }

        if (data.resume.id) {
          payload.cv = data.resume.id;
        }
      }

      const res = await this.props.contactInfoUpdate(payload);
      setSubmitting(false);
      res && res.success && this.nextStep();
    } catch (e) {
      // Do nothing
    }
  }

  render() {
    const {error, isValidating} = this.state;
    const {profile, userLocation} = this.props;
    const {step} = this.state;
    const profileUser = profile && profile.user;
    const user = {
      ...this.props.user,
      ...profileUser,
      cv: profile ? profile.cv : null,
      description: profile ? profile.description : '',
      company: (this.company || profile) ? (profile ? profile.company : this.company) : null,
    };

    return (
      <div className="sign-up-co-founder">
        <div className="sign-up-co-founder__inner">
          {isValidating ?  <Preloader className="sign-up-co-founder__preloader" /> : (
            error ? (
              <div className="sign-up-co-founder__error">
                <h1 className="sign-up-co-founder__error-title f-34 f-500">{error}</h1>
                <div className="sign-up-co-founder__error-desc f-16 f-400">Please check the link and try again</div>
              </div>
            ) : (
              <>
                <div className="sign-up-co-founder__step-number">Step {step} of {this.totalSteps}</div>
                {step === 1 && <RegisterForm onSubmit={this.onRegister} nextStep={this.nextStep} role={ROLES.co_founder} />}
                {step === 2 && <ContactInfoForm onSubmit={this.onContactInfoSubmit} userLocation={userLocation} user={user} role={ROLES.co_founder} />}
                {step === 3 && <VerifyForm />}
              </>
            )
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  profile: state.profileStore.profile.data,
  userLocation: state.userStore.userLocation
});

const mapDispatchToProps = dispatch => ({
  registerUser: userData => dispatch(registerUser(userData)),
  contactInfoUpdate: data => dispatch(contactInfoUpdate(data)),
  getUserLocation: () => dispatch(getUserLocation()),
  uploadFile: file => dispatch(uploadFile(file)),
  uploadPhoto: photo => dispatch(uploadPhoto(photo)),
  getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCoFounder);