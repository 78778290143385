import React from 'react';
import moment from "moment";
import classnames from 'classnames';
import {connect} from 'react-redux';
import {RoundButton} from '../../components/UI/RoundButton';
import {getWithdrawHistory} from '../../store/actions/paymentActions';
import {DATE_FORMAT_DD_MM_YYYY} from '../../helpers/common';
import {prettyFloatMoney} from '../../helpers/utils';
import {cancelWithdrawRequest} from '../../store/services/paymentServices';
import './index.scss';

const REQUEST_STATUSES = {
  new: {id: 'new', title: 'sent'},
  in_process: {id: 'in_process', title: 'in process'},
  completed: {id: 'completed', title: 'processed'},
  cancelled: {id: 'cancelled', title: 'cancelled'},
}

class WithdrawHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10
    }
  }

  componentDidMount() {
    this.props.getWithdrawHistory(this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.resetTime !== prevProps.resetTime) {
      this.setState({page: 1, limit: 10});
      this.props.getWithdrawHistory({page: 1, limit: 10});
    }
  }

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1
      this.props.getWithdrawHistory({
        ...this.state,
        page: nextPage,
      }, true);

      return this.setState({ ...this.state, page: nextPage })
    }
  }

  render() {
    const {page} = this.state;
    const {withdrawHistory, className} = this.props;
    const {data} = withdrawHistory;

    if (!data || !data?.total_count) {
      return null;
    }

    return (
      <div className={classnames("withdraw_history", className)}>
        <h2 className="f-26 f-500">Withdrawals History</h2>
        <ul className="withdraw_history__list">
          {data.list.map(request => (
            <RequestItem request={request} key={request.id} />
          ))}
        </ul>
        {data && page < data.total_pages && (
          <RoundButton
            type="button"
            label="Show more"
            className="withdraw_history__more"
            onClick={() => this.getNext(data.total_pages)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  withdrawHistory: state.paymentStore.withdrawHistory,
})

const mapDispatchToProps = dispatch => ({
  getWithdrawHistory: (params, isNext) => dispatch(getWithdrawHistory(params, isNext)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawHistory);

const RequestItem = ({request}) => {
  const [status, setStatus] = React.useState(request.status);

  const handleCancel = () => {
    cancelWithdrawRequest(request.id).then(res => res && res.success && setStatus(REQUEST_STATUSES.cancelled.id));
  }

  return (
    <li className="withdraw_history__list_item">
      {moment(request.created_at).format(DATE_FORMAT_DD_MM_YYYY)} Transfer {prettyFloatMoney(request.amount)} to the <span style={{textTransform: 'capitalize'}}>{request.external_account.account_type}</span> account ending in {request.external_account.last_four}. Withdrawal request {REQUEST_STATUSES[status].title}. {status === REQUEST_STATUSES.new.id && <button type="button" className="withdraw_history__cancel" onClick={handleCancel}>Cancel</button>}
    </li>
  )
}