import * as React from 'react';
import {connect} from 'react-redux';
import qs from 'qs';
import {contactInfoUpdate, getUserLocation, registerUser} from '../../store/actions/userActions';
import {getUserProfile} from '../../store/actions/profileActions';
import {uploadFile, uploadPhoto} from '../../store/actions/commonActions';
import RegisterForm from '../../components/Forms/RegisterForm';
import VerifyForm from '../../components/VerifyForm'
import {cropAvatar} from '../../components/AvatarEdit';
import ContactInfoForm from '../../components/Forms/ContactInfoForm';
import {ROLES} from '../../helpers/constants';
import './index.scss';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.totalSteps = 3;
    const {user, profile, location, match, history} = props;
    const {step} = qs.parse(location.search.replace('?', ''));
    if (user && !isNaN(step) && step > 1 && step <= this.totalSteps) {
      this.state = {step: parseInt(step)}
    } else {
      this.state = {step: profile ? 2 : 1}
      location.search && history.replace(`/sign-up/${match.params.invite}`);
    }
  }

  componentDidMount() {
    this.props.user && this.props.getUserProfile();
    this.props.getUserLocation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.setState(prevState => ({...prevState, step: 2}));
    }
  }

  nextStep = () => this.state.step < 4 && this.setState(prevState => ({step: prevState.step + 1}))

  onRegister = async (data, {setSubmitting}) => {
    const payload = {
      email: data.email,
      password: data.password,
      invite_code: null,
    }
    const res = await this.props.registerUser(payload);
    setSubmitting(false);
    res && res.success && this.nextStep();
  }

  onContactInfoSubmit = async (data, {setSubmitting, errors, setErrors}) => {
    try {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        citizenship: data.citizenship,
        citizenship_code: data.citizenship_code,
        company_name: data.company_name,
        company_description: data.description,
        description: data.about,
        cv: null,
        photo: null
      };

      if (data.photo && data.editorRef) {
        const croppedAvatar = cropAvatar(data.editorRef);
        const res = await this.props.uploadPhoto(croppedAvatar);
        res && res.id && (payload.photo = res.id);
      }

      if (data.resume) {
        const res = await this.props.uploadFile(data.resume);
        res && res.id && (payload.cv = res.id);
      }

      const res = await this.props.contactInfoUpdate(payload);
      if (res && res.success) {
        res && res.success && this.nextStep();
      }

      if (res && !res.success && res.errors) {
        setErrors({...errors, ...res.errors});
        window.scrollTo(0, 0);
      }
    } catch (e) {
      // Do nothing
    } finally {
      setSubmitting(false);
    }
  }

  render() {
    const {profile, userLocation} = this.props;
    const {step} = this.state;
    const profileUser = profile && profile.user;
    const user = {
      ...this.props.user,
      ...profileUser,
      cv: profile ? profile.cv : null,
      description: profile ? profile.description : '',
      company: profile ? profile.company : null,
    };

    return (
      <div className="sign-up">
        <div className="sign-up__inner">
          <div className="sign-up__step-number">Step {step} of {this.totalSteps}</div>
          {step === 1 && <RegisterForm onSubmit={this.onRegister} nextStep={this.nextStep} role={ROLES.founder} />}
          {step === 2 && <ContactInfoForm onSubmit={this.onContactInfoSubmit} userLocation={userLocation} user={user} role={ROLES.founder} />}
          {step === 3 && <VerifyForm />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  profile: state.profileStore.profile.data,
  userLocation: state.userStore.userLocation
});

const mapDispatchToProps = dispatch => ({
  registerUser: userData => dispatch(registerUser(userData)),
  contactInfoUpdate: data => dispatch(contactInfoUpdate(data)),
  getUserLocation: () => dispatch(getUserLocation()),
  uploadFile: file => dispatch(uploadFile(file)),
  uploadPhoto: photo => dispatch(uploadPhoto(photo)),
  getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
