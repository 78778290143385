export const CURRENCY = '€';
export const CONTACT_EMAIL = 'ask@scrambleup.com';

export const LINK_TYPES = {
  phone: 'phone',
  web: 'web',
  mail: 'mail'
}

export const SWAP_REQ_STATUSES = {
  pending: 'pending', // Fundraising Step 2 - Партнер ждет ответа от нас
  accepted: 'accepted',  // Состояние Accepted
  declined: 'declined', // Состояние Rejected
  enabled: 'enabled', // Можно отправить запрос на своп, ID = null
  pending_out: 'pending_out', // Я отправил запрос партнеру
  pending_in: 'pending_in', // Партнер ждет ответа от нас
  disabled: 'disabled',
}

export const SWAP_STATUSES = {
  partner: 'partner',
  selected: 'selected',
  not_selected: 'not_selected',
  swapped: 'swapped',
}

export const LOAN_GROUPS_SHORT_NAMES = {
  conservative: 'A',
  moderate: 'B',
  growth: 'C',
}

export const LOAN_GROUPS = {
  conservative: {
    id: 'conservative',
    seniority: 'Senior',
    full_name: 'Group A',
    short_name: 'A',
    scope_name: 'Group A only',
    downside_protection: 'Triple-secured',
    disclaimer:
      'Select this group if you want to earn a stable income going along with reduced risk.',
  },
  moderate: {
    id: 'moderate',
    seniority: 'Subordinated',
    full_name: 'Group B',
    short_name: 'B',
    scope_name: 'Groups A and B',
    downside_protection: 'Double-secured',
    disclaimer:
      'Select this group if you are open to increased risk and want to earn higher yields.',
  },
  growth: {
    id: 'growth',
    seniority: 'Junior',
    full_name: 'Group C',
    short_name: 'C',
    scope_name: 'Groups A, B and C',
    downside_protection: '',
    disclaimer: '',
  },
};

export const USER_STATUSES = {
  new: 'new',
  verified: 'verified',
  blocked: 'blocked',
}

export const ROLES = {
  founder: 'founder',
  co_founder: 'co_founder'
}

export const LOAN_TYPES = {
  old: 'old',
  new: 'new',
  mixed: 'mixed'
}

export const FLAT_FEE = 6;