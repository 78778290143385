import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage} from '../../helpers/utils';

export const uploadImage = fileData => {
  let file;
  if (fileData instanceof FormData) {
    file = fileData;
  } else {
    file = new FormData();
    file.append('file', fileData);
  }

  return axios.post(Pathes.File.photo, file)
    .then(response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 201) {
        return ({data: response.data, success: true});
      }

      throw new Error(message)
    })
    .catch(() => ({success: false}));
}