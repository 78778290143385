import React from 'react';
import {changePassword} from "../../store/services/userServices";
import ChangePassForm from "../../components/Forms/ChangePassForm";

const ChangePasswordModal = ({onClose}) => {

  const onSubmit = async values => {
    const payload = {
      old_password: values.oldPassword,
      new_password: values.password1,
    };

    const res = await changePassword(payload);
    if (res && res.success) {
     onClose();
    }
  }

  return (
    <ChangePassForm
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ChangePasswordModal;