import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage, getQuery} from '../../helpers/utils';
import {GET_ACTIVITY_LIST} from '../types/activityTypes';

const getActivityListRequest = () => ({ type: GET_ACTIVITY_LIST.REQUEST });
const getActivityListSuccess = payload => ({type: GET_ACTIVITY_LIST.SUCCESS, payload});
const getActivityListFailure = error => ({type: GET_ACTIVITY_LIST.FAILURE, error});

export const getActivityList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getActivityListRequest());
    return axios.get(Pathes.Activity.list + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().activityStore.activityList.data;
          if (!isNext || !prevData) {
            dispatch(getActivityListSuccess(data));
            return { data, success: true, message};
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }

          dispatch(getActivityListSuccess(updatedData));
          return { data: updatedData, success: true, message};
        }
        throw new Error(message)
      }).catch(e => dispatch(getActivityListFailure(e.message)));
  };
}