import React from 'react';
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {RoundButton} from '../../components/UI/RoundButton';
import {getPrepayInfo, prepayLoan} from '../../store/actions/paymentActions';
import Preloader from '../../components/UI/Preloader';
import {prettyFloatMoney} from '../../helpers/utils';
// import FundsPrepayFAQ from '../../components/FAQ/FundsPrepayFAQ';
import {getBalance} from '../../store/actions/dashboardActions';
import {MoneyInputField} from '../../components/UI/MoneyInputField';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import {ROLES} from '../../helpers/constants';
import WarningBlock from '../../components/UI/WarningBlock';
import './index.scss';

const VALIDATION_SCHEMA = (min, max) => (
  Yup.object().shape({
    amount: Yup.number()
      .min(min, `Minimal amount ${prettyFloatMoney(min)}`)
      .max(max, !max ? 'No available cash' : `Maximum amount ${prettyFloatMoney(max)}`)
      .required('Amount is required'),
  })
)

class PaymentsPrepayPage extends React.Component {
  componentDidMount() {
    this.props.getPrepayInfo();
    this.props.getMissingProfileDetails();
  }

  onSubmit = ({amount}, formikBag) => {
    const cash = Number(amount);
    cash && this.props.prepayLoan(cash).then(res => {
      if (res && res.success) {
        this.props.getBalance()
        formikBag && formikBag.resetForm();
      }
    });

    formikBag && formikBag.setSubmitting(false);
  }

  render() {
    const {user, prepayInfo} = this.props;
    const {data, loading} = prepayInfo;

    return (
      <div className="payments-prepay-page">
        {loading ? (<Preloader className="payments-prepay-page__preloader" />) : (
          <Formik
            enableReinitialize
            onSubmit={this.onSubmit}
            validationSchema={VALIDATION_SCHEMA(0, (data && data.available_cash) || 0)}
            initialValues={{
              amount: ''
            }}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit, isSubmitting }) => (
              <>
              {user.role !== ROLES.founder && (
                <WarningBlock className="payments-prepay-page__cofounder" withBorder>
                  <div className="f-16 f-500">Loan prepayment is available only to the company administrator.</div>
                </WarningBlock>
              )}
                <form onSubmit={handleSubmit}>
                  <div>
                    <p className="f-16">From cash account</p>
                    <p className="f-26">{prettyFloatMoney(data && data.available_cash)}</p>
                  </div>

                  <div className="payments-prepay-page__block">
                    <p className="f-16">Total debt</p>
                    <p className="f-26">{prettyFloatMoney(data && data.total_debt)}</p>
                  </div>
                  <div className="payments-prepay-page__block">
                    <MoneyInputField
                      name="amount"
                      label="Amount"
                      value={values.amount}
                      onChange={({value}) => setFieldValue('amount', value)}
                      disabled={!(data && data.total_debt)}
                      onClear={() => setFieldValue('amount', '')}
                      className="payments-prepay-page__amount"
                      error={errors.amount && touched.amount && errors.amount}
                    />
                    <p className="payments-prepay-page__amount-desc">No commission</p>
                  </div>
                  <RoundButton
                    type="submit"
                    label="Prepay loans"
                    onSubmit={handleSubmit}
                    disabled={user.role !== ROLES.founder || !(data && data.total_debt) || isSubmitting}
                    className="payments-prepay-page__block payments-prepay-page__submit"
                    fillBackground
                  />
                </form>
              </>
            )}
          </Formik>
        )}
        {/*<FundsPrepayFAQ />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  prepayInfo: state.paymentStore.prepayInfo,
})

const mapDispatchToProps = dispatch => ({
  getPrepayInfo: () => dispatch(getPrepayInfo()),
  prepayLoan: amount => dispatch(prepayLoan(amount)),
  getBalance: () => dispatch(getBalance()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPrepayPage);