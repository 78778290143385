import { createBrowserHistory } from 'history';
import moment from 'moment';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import axios from '../axios-api';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import userReducer from '../store/reducers/userReducer';
import {logoutUser} from './actions/userActions';
import commonReducer from './reducers/commonReducer';
import fundraisingReducer from './reducers/fundraisingReducer';
import paymentReducer from './reducers/paymentReducer';
import dashboardReducer from './reducers/dashboardReducer';
import founderReducer from './reducers/founderReducer';
import activityReducer from './reducers/activityReducer';
import loanReducer from './reducers/loanReducer';
import profileReducer from './reducers/profileReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  userStore: userReducer,
  commonStore: commonReducer,
  fundraisingStore: fundraisingReducer,
  paymentStore: paymentReducer,
  dashboardStore: dashboardReducer,
  founderStore: founderReducer,
  activityStore: activityReducer,
  loanStore: loanReducer,
  profileStore: profileReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  const {createLogger} = require(`redux-logger`);
  const logger = createLogger({
    diff: true,
    collapsed: true,
    titleFormatter: action => `[action] ${action.type}`,
    level: {prevState: false, nextState: false, error: false},
  });
  middleware.push(logger);
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadFromLocalStorage();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveToLocalStorage({
    userStore: {
      user: store.getState().userStore.user,
      token: store.getState().userStore.token,
      expireDate: store.getState().userStore.expireDate,
    }
  });
});

axios.interceptors.request.use(config => {
  try {
    const {expireDate, token} = store.getState().userStore;

    if (expireDate && moment(expireDate).isBefore()) {
      return store.dispatch(logoutUser());
    }

    if (token) {
      config.headers.Authorization = `Token ${store.getState().userStore.token}`;
    }
  } catch (e) {
    // do nothing, user is not logged in
  }

  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logoutUser());
    }
    return error.response ? error.response : {data: {message: error.message}};
  }
);

export default store;

