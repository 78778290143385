import React from 'react';
import moment from 'moment';
import {prettyFloatMoney} from '../../helpers/utils';
import {DATE_FORMAT_D, DATE_FORMAT_D_MMMM, DATE_FORMAT_DD_MMMM, DATE_FORMAT_M} from '../../helpers/common';
import {connect} from 'react-redux';
import InfoToolboxRed from '../../components/UI/InfoToolboxRed';
import {getBalance, getFundraisingDates} from '../../store/actions/dashboardActions';
import './index.scss';

class TopHeader extends React.Component {
  componentDidMount() {
    this.props.getFundraisingDates();
    this.props.getBalance();
  }

  getFundraisingState = () => {
    const { fundraisingDates } = this.props;
    if (!fundraisingDates) { return ''; }

    if (fundraisingDates.is_active && fundraisingDates.end_date) {
      return `Active fundraising until ${moment(fundraisingDates.end_date).format(DATE_FORMAT_DD_MMMM)}`;
    }

    if (!fundraisingDates.is_active && fundraisingDates.start_date && fundraisingDates.end_date) {
      if (moment(fundraisingDates.end_date).format(DATE_FORMAT_M) === moment(fundraisingDates.start_date).format(DATE_FORMAT_M)) {
        return `Next fundraising: ${moment(fundraisingDates.start_date).format(DATE_FORMAT_D)} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
      }
      return `Next fundraising: ${moment(fundraisingDates.start_date).format(DATE_FORMAT_D_MMMM)} - ${moment(fundraisingDates.end_date).format(DATE_FORMAT_D_MMMM)}`;
    }
    // Fundraising not set on Database
    return '';
  }

  render() {
    const { balance } = this.props;
    const fundraisingState = this.getFundraisingState();
    const showWarning = balance && (balance.next_payment > balance.cash);

    return (
      <div className="top-header">
        <div className="container-inner">
          <div className="top-header__inner">
            <div className="top-header__dates f-16 f-400">{fundraisingState}</div>
            <div className="top-header__summary">
              <div className="top-header__payment">
                <span className="f-16 f-400">Next repayment:</span>
                <span className="f-16 f-500 black">{prettyFloatMoney(balance && balance.next_payment)}</span>
                {showWarning && (
                  <InfoToolboxRed style={{marginLeft: '5px'}} place="bottom">
                    <p className="top-header__warning">Insufficient funds for a next repayment. Please add funds to your account.</p>
                  </InfoToolboxRed>
                )}
              </div>
              <div className="top-header__cash">
                <span className="f-16 f-400">Cash: </span>
                <span className="f-16 f-500 black">{prettyFloatMoney(balance && balance.cash)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  balance: state.dashboardStore.balance,
  fundraisingDates: state.dashboardStore.fundraisingDates,
});

const mapDispatchToProps = dispatch => ({
  getFundraisingDates: () => dispatch(getFundraisingDates()),
  getBalance: () => dispatch(getBalance()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);