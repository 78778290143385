import * as React from 'react'
import * as classnames from 'classnames'
import {Field} from 'formik';
import {getCountries} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json'
import './index.scss';

class CountrySelectField extends React.Component {
  getCountryObj = e => {
    e.preventDefault();
    e.target.value
      ? this.props.onChange(en[e.target.value], e.target.value)
      : this.props.onChange('', '')
  }

  render() {
    const {label, name, value, error, className } = this.props
    const excludeCountries = ['AF', 'AS', 'BS', 'BW', 'KP', 'ET', 'GH', 'GU', 'IR', 'IQ', 'LY', 'NG', 'PK', 'PA', 'PR', 'WS', 'SA', 'LK', 'SY', 'TT', 'TN', 'VI', 'YE'];
    const countries = getCountries()
      .filter(countryCode => !excludeCountries.includes(countryCode))
      .map(countryCode => ({
        code: countryCode,
        label: en[countryCode] || 'Unknown'
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div className={classnames("country-select-field", error && "country-select-field_error", className)}>
        <div className="country-select-field__inner">
          <Field
            name={name}
            id={name}
            as="select"
            onChange={this.getCountryObj}
            className={classnames("country-select-field__select", value && "country-select-field__select-filled")}
            value={this.props.value}
          >
            <option value=''>Select country</option>
            {countries.map(country => (
              <option value={country.code} key={country.code} className="country-select-field__option">{country.label}</option>
            ))}
          </Field>
          <label htmlFor={name} className="country-select-field__label">{label}</label>
          <svg  className="country-select-field__arrow-icon" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.43111 8.75869C7.75111 9.08044 8.26667 9.08044 8.56889 8.75869L15.7333 1.55511C16.0889 1.19762 16.0889 0.625621 15.7333 0.268123C15.3778 -0.0893744 14.8089 -0.0893744 14.4533 0.268123L8.56889 6.18471C8.24889 6.50645 7.73333 6.50645 7.43111 6.18471L1.54667 0.285998C1.19111 -0.0714994 0.622222 -0.0714994 0.266667 0.285998C-0.0888889 0.643496 -0.0888889 1.21549 0.266667 1.57299L7.43111 8.75869Z" fill="#9E98A3"/>
          </svg>
        </div>
        <div className={classnames("country-select-field__error", error && "country-select-field__error_visible")}>{error}</div>
      </div>
    )
  }
}

export default CountrySelectField;