import React from 'react';
import * as classnames from 'classnames';
import {ArrowUp} from '../Icons';
import OutsideClickHandler from 'react-outside-click-handler';
import './index.scss';

export const ACCOUNT_TYPES = {
  transferwise: 'transferwise',
  paysend: 'paysend',
  bank: 'bank'
}

const AccountSelect = ({ value, options, onSelect, error, disabled, className }) => {
  const [show, setShow] = React.useState(false);
  return (
    <div
      className={classnames(
        "account-select",
        show && "account-select__active",
        error && "account-select__error",
        className
      )}
      onClick={() => !disabled && setShow(!show)}
    >
      <div className="account-select__inner row">
        <p className="account-select__left f-500 f-20">
          {value
            ? ((value.account_type !== ACCOUNT_TYPES.bank || value.last_four === null) ? value.title : `•••• •••• •••• ${value.last_four}`)
            : 'Choose account'
          }
        </p>
        <ArrowUp className="account-select__right" />
      </div>

      <div className="account-select__menu">
        <OutsideClickHandler
          onOutsideClick={() => setShow(false)}
          disabled={!show}
        >
          <div className="account-select__menu-inner">
            {!options.length && <div className="account-select__menu-empty">No bank accounts</div>}
            {options.map(option => (
              <div
                key={option.id}
                onClick={() => onSelect(option)}
                className="account-select__option hov f-500 f-20"
              >
                {
                  (option.account_type !== ACCOUNT_TYPES.bank || option.last_four === null)
                    ? option.title
                    : `ending in ${option.last_four}`
                }
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default AccountSelect;