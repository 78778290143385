import React from 'react';
import moment from 'moment';
import * as classnames from 'classnames';
import {WarningIcon} from '../../components/UI/Icons';
import {DATE_FORMAT_DD_MMMM, DATE_FORMAT_DD_MMMM_YYYY} from '../../helpers/common';
import {prettyFloatMoney} from '../../helpers/utils';
import './index.scss';

const PaymentBlock = ({ isMain, payment, cash, className }) => {
  if (!payment) {
    return null;
  }
  const { 
    deadline, total_amount, 
    mutual_insurance, principal, 
    charge, interest, success_fee
  } = payment;

  const showWarning = isMain && (!cash || (cash && Number(total_amount) > Number(cash)));

  return (
    <div className={classnames("payment-block", isMain && "payment-block__main", className)}>
      <div className="payment-block__right">
        <p className="f-16">Due date</p>
        <p className={`${isMain ? 'f-42' : 'f-26'} f-500`}>{moment(deadline).format(isMain ? DATE_FORMAT_DD_MMMM_YYYY : DATE_FORMAT_DD_MMMM)}</p>
      </div>
      <div className="payment-block__left">
        <div>
          <p className="f-16">Total amount</p>
          <div className="payment-block__amount">
            <p className={`${isMain ? 'f-42' : 'f-26'} f-500 tl`}>{prettyFloatMoney(total_amount)}</p>
            {showWarning && <WarningIcon style={{marginLeft: '10px'}} />}
          </div>
          {showWarning && <p className="payment-block__error f-16">Insufficient funds for a next repayment. Please add funds to your account.</p>}
        </div>

        {!!mutual_insurance && (
          <div>
            <p className="f-16">Mutual Insurance</p>
            <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>{prettyFloatMoney(mutual_insurance)}</p>
          </div>
        )}

        {!!interest && (
          <div>
            <p className="f-16">Interest</p>
            <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>{prettyFloatMoney(interest)}</p>
          </div>
        )}

        {!!success_fee && (
          <div>
            <p className="f-16">Success fee</p>
            <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>{prettyFloatMoney(success_fee)}</p>
          </div>
        )}

        {!!principal && (
          <div>
            <p className="f-16">Principal</p>
            <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>{prettyFloatMoney(principal)}</p>
          </div>
        )}

        {!!charge && (
          <div>
            <p className="f-16">Penalty for missed monthly repayment</p>
            <p className={`${isMain ? 'f-26' : 'f-18'} f-500 tl`}>{prettyFloatMoney(charge)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentBlock;