import React, {useState} from 'react';
import * as classnames from 'classnames';
import {Link, NavLink} from 'react-router-dom';
import Logo from '../UI/Icons/Logo';
import UserIcon from '../UI/Icons/UserIcon';
import {getFullname, getNameInitials} from '../../helpers/utils';
import {useSelector} from 'react-redux';
import './index.scss';

export const NAV_ROUTES = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Fundraising', path: '/fundraising' },
  { label: 'Funds', path: '/payments' },
  { label: 'Loans', path: '/loans' },
  { label: 'Founders', path: '/founders' },
  { label: 'Activity', path: '/activity' },
  // { label: 'FAQ', path: '/help' },
]

const Navigation = () => {
  const [showAvatar, setShowAvatar] = useState(true);
  const user = useSelector(state =>  state.userStore.user);
  const isParticipating = useSelector(state => state.fundraisingStore.isParticipating);

  return (
    <div className="navigation__container">
      <div className="navigation__top">
        <Link to="/dashboard" className="navigation__logo">
          <Logo />
        </Link>
        <nav className="navigation">
          {NAV_ROUTES.map((route) => (
            <NavLink
              key={route.label}
              to={route.path}
              className={classnames("navigation__item", `navigation__item-${route.path.replace('/','')}`)}
              activeClassName="navigation__item_active"
            >
              {route.label}
            </NavLink>
          ))}
        </nav>
      </div>
      {user && (
        <Link className="navigation__user" to="/profile" >
          {(showAvatar && user.photo) ? (
            <div className={classnames("navigation__user-avatar", isParticipating && "active")}>
              <img src={user.photo.file} alt={getNameInitials(user.first_name, user.last_name)} onError={() => setShowAvatar(false)} />
            </div>
          ) : (
            <UserIcon className="navigation__user-icon" strokeColor={isParticipating && "#FFD200"} />
          )}
          <div className="f-500">{getFullname(user.first_name, user.last_name, 'Profile')}</div>
        </Link>
      )}
    </div>
  )
};

export default Navigation;