import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import NotificationBlock from '../../NotificationBlock';
import FundraisingInfo from '../../FundraisingInfo';
import Avatar from '../../../components/UI/Avatar';
import {getNameInitials} from '../../../helpers/utils';
import {getCoFoundersList, participateFundraising} from '../../../store/actions/fundraisingActions';
import {ROLES, USER_STATUSES} from '../../../helpers/constants';
import CoFounderFundraisingAcceptForm from '../../../components/Forms/CoFounderFundraisingAcceptForm';
import {NOTIFICATIONS} from '../../../components/Notification/list';
import {MyLink} from "../../../components/MyLink";
import {APP_LINKS} from "../../../helpers/links";
// import CustomFoundersList from '../founders';
import './index.scss';

class FundraisingSettings extends Component {
  componentDidMount() {
    this.props.getCoFoundersList();
  }

  onParticipationSubmit = (values) => {
    this.props.participateFundraising({ status: values.has_accepted_settings }).then(res => {
      if (res && res.success) {
        this.props.getCoFoundersList();
      }
    });
  }

  render() {
    const {status, user, coFounders, currentStep, onReset, onGoalEdit} = this.props;
    const {start_date, end_date} = status.data;
    let notifications = [];

    if (coFounders.data) {
      coFounders.data.filter(founder => !founder.is_confirmed).length
        ? notifications.push(NOTIFICATIONS.cofounders_confirm_required())
        : notifications.push(NOTIFICATIONS.cofounders_confirmed())
    }

    if (user.role === ROLES.founder) {
      notifications.push(NOTIFICATIONS.you_can_change_fundraising_settings(start_date));
    }

    if (user.role === ROLES.co_founder) {
      if (user.status !== USER_STATUSES.verified) {
        notifications.push(NOTIFICATIONS.verify_identity_cofounder());
      }
      if (currentStep < 2) {
        notifications.push(NOTIFICATIONS.loan_disbursements_starts(end_date));
      }
    }

    return (
      <div className="fundraising-settings">
        {user.role === ROLES.founder
          ? <h1 className="f-42 f-500">Your settings have been successfully saved</h1>
          : (
            <h1 className="f-42 f-500">
              {currentStep < 2
                ? "Wait while your company administrator sets up new fundraising"
                : (status.data.is_confirmed
                    ? 'Your settings have been successfully saved'
                    : 'Confirm your participation in the round'
                )}
            </h1>
          )
        }

        <NotificationBlock
          notifications={notifications}
          className="fundraising-settings__notifications"
        />

        <FundraisingInfo
          onGoalEdit={onGoalEdit}
          className="fundraising-settings__dashboard"
        />

        {currentStep >= 2 && (
          <>
            {user.role === ROLES.co_founder && (
              <>
                {status.data.is_confirmed ? (
                  <div className="fundraising-settings__reset cofounder">
                    <div>
                      <h2 className="f-26 f-500">Changed your mind?</h2>
                      <p className="f-16 f-400">You can refuse to participate in the round before it starts.</p>
                    </div>
                    <button type="button" className="f-16 f-400 hov" onClick={onReset}>Reset fundraising</button>
                  </div>
                ) : (
                  <CoFounderFundraisingAcceptForm
                    onSubmit={this.onParticipationSubmit}
                    className="fundraising-settings__participate"
                    status={status.data}
                    disabled={user.status !== USER_STATUSES.verified}
                  />
                )}
              </>
            )}

            {coFounders.data && !!coFounders.data.length && coFounders.data.length > 1 && (
              <div className="fundraising-settings__co-founders">
                <h2 className="f-26 f-500">Confirmation from founders</h2>
                <p className="fundraising-settings__co-founders-desc f-16 f-400">Before the round starts, all co-founders must confirm their agreement to participate in the round with the specified fundraising settings.</p>
                {coFounders.data.map(founder => (
                  <div className="fundraising-settings__co-founder" key={founder.id}>
                    <MyLink href={user.role_id === founder.id ? APP_LINKS.profile : `${APP_LINKS.founders}/${founder.id}`} className="fundraising-settings__co-founder-left">
                      <Avatar
                        size={56}
                        alt={founder.avatar.name}
                        src={founder.avatar.file}
                        nameInitials={getNameInitials(founder.first_name, founder.last_name)}
                        active={!coFounders.data.filter(founder => !founder.is_confirmed).length}
                        className="fundraising-settings__co-founder-avatar"
                      />
                      <div className="fundraising-settings__co-founder-content">
                        <div className="f-18 f-500 tl">{founder.full_name}</div>
                        <div className="f-14 f-400">Co-founder</div>
                      </div>
                    </MyLink>
                    <div className="fundraising-settings__co-founder-right">
                      <div className={classnames("fundraising-settings__co-founder-status", founder.is_confirmed && "active")}>
                        {founder.is_confirmed ? "Confirmed " : "Not ready yet"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {user.role === ROLES.founder && (
              <div className="fundraising-settings__reset founder">
                <div>
                  <h2 className="f-26 f-500">Changed your mind?</h2>
                  <p className="f-16 f-400">You can refuse to participate in the round before it starts.</p>
                </div>
                <button type="button" className="f-16 f-400 hov" onClick={onReset}>Reset fundraising</button>
              </div>
            )}
            {/*<FundraisingSettingsFAQ />*/}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  status: state.fundraisingStore.status,
  coFounders: state.fundraisingStore.coFounders,
});

const mapDispatchToProps = dispatch => ({
  getCoFoundersList: () => dispatch(getCoFoundersList()),
  participateFundraising: payload => dispatch(participateFundraising(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundraisingSettings);
