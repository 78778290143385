import React from 'react';

const UserIcon = ({ strokeColor, className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15" stroke={strokeColor || "#FFF"} strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4974 16.8462C21.4511 17.0097 23 18.6561 23 20.6451V21.6041C23 22.3075 22.5663 22.9054 21.8961 23.1247C20.2088 23.6773 18.1812 24.0001 16 24.0001C13.8179 24.0001 11.7902 23.6773 10.1039 23.1247C9.43368 22.9049 9 22.3075 9 21.6041V20.6451C9 18.6561 10.5484 17.0097 12.5021 16.8462C13.47 17.5911 14.6828 18.035 16 18.035C17.3162 18.035 18.5291 17.5911 19.4974 16.8462Z" fill="#FFF"/>
      <path d="M15.9993 16.6471C18.3937 16.6471 20.3347 14.7114 20.3347 12.3235C20.3347 9.93572 18.3937 8 15.9993 8C13.6049 8 11.6639 9.93572 11.6639 12.3235C11.6639 14.7114 13.6049 16.6471 15.9993 16.6471Z" fill="#FFF"/>
    </svg>
  );
};

export default UserIcon;