import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { connect } from 'react-redux';
import ROUTES from './routes';
import { setInnerWidth } from './store/actions/commonActions';
import {ModalProvider} from './components/ModalProvider';

const allowedRoutes = user => {
  return ROUTES.filter(route => (route.auth ? route.auth(user) : true)) || [];
};

function App({ user, setInnerWidth }) {
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    setInnerWidth(window.innerWidth);
    document.querySelector(':root').style
      .setProperty('--vh', `${vh}px`);
  };

  return (
    <div className="App">
      {renderRoutes(allowedRoutes(user))}
      <ModalProvider />
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.userStore.user,
});

const mapDispatchToProps = dispatch => ({
  setInnerWidth: width => dispatch(setInnerWidth(width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
