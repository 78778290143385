import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InputTextField } from '../../UI/InputTextField';
import CountrySelectField from '../../UI/CountrySelectField';
import TextareaField from '../../UI/TextareaField';
import { RoundButton } from '../../UI/RoundButton';
import { RoundLink } from '../../UI/RoundLink';
import { checkForValidFile } from '../../../helpers/utils';
import { CV_ALLOWED_FORMATS, PHOTO_ALLOWED_FORMATS } from '../../../helpers/common';
import CVUpload from '../../CVUpload';
import PhotoUpload from '../../PhotoUpload';
import {ROLES} from '../../../helpers/constants';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  first_name: Yup.string()
    .max(40, 'First name exceeds limit of 40')
    .required('First name is required'),
  last_name: Yup.string()
    .max(40, 'Last name exceeds limit of 40')
    .required('Last name is required'),
  citizenship: Yup.string()
    .required('Citizenship is required'),
  company_name: Yup.string().max(20, 'Company name exceeds limit of 20'),
  description: Yup.string().max(1000, 'Exceeded limit 1000 symbols'),
  about: Yup.string().max(1000, 'Exceeded limit 1000 symbols'),
});

class ContactInfoForm extends React.Component {
  getInitialValues = (user) => ({
    first_name: (user && user.first_name) || '',
    last_name: (user && user.last_name) || '',
    citizenship: (user && user.citizenship) || '',
    citizenship_code: (user && user.citizenship_code) || '',
    company_name: (user && user.company && user.company.name) || '',
    description: (user && user.company && user.company.description) || '',
    about: (user && user.description) || '',
    resume: (user && user.cv) || null,
    photo: (user && user.photo) || null,
    editorRef: null,
  })

  render() {
    const {userLocation, user, onSubmit, disableSkip, disableTitle, defaultSubmit, role, onCancel} = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues(user, userLocation)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values, errors, touched, setFieldError, handleChange, handleSubmit, setFieldValue,
        }) => (
          <form className="contact-info-form" onSubmit={handleSubmit}>
            {!disableTitle && <h1 className="contact-info-form__title f-500">Contact information</h1>}
            <InputTextField
              label="First name *"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              onClear={() => setFieldValue('first_name', '')}
              error={errors.first_name && touched.first_name && errors.first_name}
              className="contact-info-form__row"
            />
            <InputTextField
              label="Last name *"
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              onClear={() => setFieldValue('last_name', '')}
              error={errors.last_name && touched.last_name && errors.last_name}
              className="contact-info-form__row"
            />
            <CountrySelectField
              label="Citizenship *"
              name="citizenship"
              value={values.citizenship_code}
              onChange={(country, code) => {
                setFieldValue('citizenship', country);
                setFieldValue('citizenship_code', code);
              }}
              error={errors.citizenship && touched.citizenship && errors.citizenship}
              className="contact-info-form__row"
            />

            <InputTextField
              label="Company name"
              name="company_name"
              value={values.company_name}
              onChange={handleChange}
              onClear={() => setFieldValue('company_name', '')}
              error={errors.company_name && touched.company_name && errors.company_name}
              disabled={role === ROLES.co_founder}
              className="contact-info-form__row"
              readOnly={role === ROLES.co_founder}
            />

            {role !== ROLES.co_founder && (
              <TextareaField
                label="Company description"
                name="description"
                minRows={2}
                value={values.description}
                onChange={handleChange}
                limit={1000}
                error={errors.description && touched.description && errors.description}
                disabled={role === ROLES.co_founder}
                className="contact-info-form__row"
              />
            )}

            <TextareaField
              label="About you"
              name="about"
              minRows={2}
              value={values.about}
              onChange={handleChange}
              error={errors.about && touched.about && errors.about}
              limit={1000}
              className="contact-info-form__row"
            />
            <p className="contact-info-form__about-intro">
              Introduce yourself to the investors (describe your education, work experience, company, hobbies).
            </p>
            <CVUpload
              label="Upload your CV"
              name="resume"
              value={values.resume}
              error={errors.resume}
              onChange={e => {
                const file = e.target.files[0];
                const { isValid, type, size } = checkForValidFile(file, CV_ALLOWED_FORMATS, 12582912);
                !isValid && size && setFieldError('resume', 'File size is too big');
                !isValid && type && setFieldError(
                  'resume', 'Incorrect file type. Upload your CV in pdf or doc format.');
                isValid && setFieldValue('resume', file);
              }}
              onRemove={() => setFieldValue('resume', null)}
              className="contact-info-form__row"
            />
            <PhotoUpload
              label="Upload your photo"
              name="photo"
              value={values.photo}
              onChange={e => {
                const file = e.target.files[0];
                const { isValid, type, size } = checkForValidFile(file, PHOTO_ALLOWED_FORMATS, 12582912);
                !isValid && size && setFieldError('photo', 'File size is too big');
                !isValid && type && setFieldError(
                  'photo', 'Incorrect file type. Upload your photo in jpg or png format.');
                isValid && setFieldValue('photo', file);
              }}
              setFieldValue={setFieldValue}
              onRemove={() => setFieldValue('photo', null)}
              error={errors.photo}
              className="contact-info-form__row"
            />

            <div className="contact-info-form__buttons">
              {onCancel && (
              <RoundButton
                label="Cancel"
                type="button"
                onClick={onCancel}
                className="contact-info-form__button"
              />
              )}
              <RoundButton
                label={defaultSubmit ? 'Save' : 'Continue'}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                withArrow={!defaultSubmit}
                className="contact-info-form__button"
              />
              {!disableSkip && (
              <RoundLink
                label="Continue Later"
                path="/"
                className="contact-info-form__link"
              />
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default ContactInfoForm;
