import React, {useEffect} from 'react';
import * as classnames from 'classnames';
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import { prettyFloatMoney } from '../../helpers/utils';
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_DD_MMM } from '../../helpers/common';
import { APP_LINKS } from '../../helpers/links';
import Preloader from '../../components/UI/Preloader';
import config from '../../config';
import {RoundLink} from '../../components/UI/RoundLink';
import {MyLink} from '../../components/MyLink';
import {getLoanDetail, getPaymentHistory} from "../../store/actions/loanActions";
import './index.scss';

const LoanInfo = ({ loan }) => {
  const dispatch = useDispatch();
  const  { loanDetail, paymentHistory } = useSelector(state => state.loanStore);
  const { amounts } = loan;
  const { data, loading } = loanDetail;

  useEffect(() => {
    dispatch(getLoanDetail(loan.fundraising_id));
    dispatch(getPaymentHistory(loan.fundraising_id, { page: 1, limit: 100 }));
  }, []);

  return (
    <div className="loan-info">
      {loading ? <Preloader className="loan-info__preloader" /> : (
        <>
          <div className="loan-info__header">
            <div>
              <p className="f-16">Started</p>
              <p className="f-22 f-500">{data ? moment(data.started).format(DATE_FORMAT_DD_MM_YYYY) : '-'}</p>
            </div>

            <div>
              <p className="f-16">End date</p>
              <p className="f-22 f-500">{data ? moment(data.end_of_grace_period).format(DATE_FORMAT_DD_MM_YYYY) : '-'}</p>
            </div>

            <div>
              <p className="f-16">Loan total</p>
              <p className="f-22 f-500 tl">{prettyFloatMoney(amounts.total_loan_amount)}</p>
            </div>

            <div>
              <p className="f-16">Outstanding</p>
              <p className="f-22 f-500 tl">{prettyFloatMoney(amounts.outstanding)}</p>
            </div>
          </div>

          {!!data?.loans.length && (
            <div className="loan-info-details">
              <h2 className="f-26 f-500">Loan details</h2>

              <div className="loan-info-details__table">
                {data.loans[0].fees.success_fee ? (
                  <div className="loan-info-details__thead success_fee">
                    <div className="loan-info-details__thead-col">Loan amount</div>
                    <div className="loan-info-details__thead-col">Success fee</div>
                    <div className="loan-info-details__thead-col">Outstanding</div>
                    <div className="loan-info-details__thead-col">Interest rate</div>
                    <div className="loan-info-details__thead-col">Loan group</div>
                  </div>
                ) : (
                  <div className="loan-info-details__thead six_fee">
                    <div className="loan-info-details__thead-col">Loan amount</div>
                    <div className="loan-info-details__thead-col">Outstanding</div>
                    <div className="loan-info-details__thead-col">Loan term</div>
                    <div className="loan-info-details__thead-col">Fee for each <br /> 6 months</div>
                    <div className="loan-info-details__thead-col">Loan group</div>
                  </div>
                )}

                {data.loans[0].fees.success_fee ? (
                  <div className="loan-info-details__tbody">
                    {data && data.loans.map((loan, idx) => (
                      <div className="loan-info-details__tbody-row success_fee" key={idx}>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.total_amount)}</div>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.fees.success_fee)}</div>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.outstanding)}</div>
                        <div className="loan-info-details__tbody-col">{`${Number(loan.interest_rate)}%`}</div>
                        <div className="loan-info-details__tbody-col">{loan.risk_group.title}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="loan-info-details__tbody">
                    {data && data.loans.map((loan, idx) => (
                      <div className="loan-info-details__tbody-row six_fee" key={idx}>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.total_amount)}</div>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.outstanding)}</div>
                        <div className="loan-info-details__tbody-col">{loan.loan_term || '-'}</div>
                        <div className="loan-info-details__tbody-col">{prettyFloatMoney(loan.fees.six_fee)}</div>
                        <div className="loan-info-details__tbody-col"><MyLink href={APP_LINKS.helpLoanTerms} underline>{loan.risk_group.title}</MyLink></div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}


          <div className={classnames('loan-info-contracts', data?.insurance_strategy)}>
            <div className="loan-info-contracts__header">
              <h2 className="f-26 f-500">Financing agreements</h2>
              <RoundLink
                label="Download all files (pdf)"
                path={`${config.apiURL}fundraisings/${data?.id}/agreements/`}
                className="loan-info-contracts__download"
                fillBackground
                isExternal
              />
            </div>

            {data ? (
              <div className="loan-info-contracts__table">
                <div className="loan-info-contracts__thead">
                  <div className="loan-info-contracts__thead-col">Loan type</div>
                  <div className="loan-info-contracts__thead-col">Loan number</div>
                  <div className="loan-info-contracts__thead-col">Financing agreement</div>
                  <div className="loan-info-contracts__thead-col">Surety agreement</div>
                </div>
                <div className="loan-info-contracts__tbody">
                  {data.agreements.map(agreement => (
                    <div className="loan-info-contracts__tbody-row" key={agreement.id}>
                      <div className="loan-info-contracts__tbody-col">{agreement.risk_group_title}</div>
                      <div className="loan-info-contracts__tbody-col">{`Loan #${agreement.id}`}</div>
                      <div className="loan-info-contracts__tbody-col"><MyLink href={`${config.apiURL}loans/${agreement.id}/agreement/`} isExternal>Download</MyLink></div>
                      <div className="loan-info-contracts__tbody-col"><MyLink href={`${config.apiURL}loans/${agreement.id}/surety_agreement/`} isExternal>Download</MyLink></div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="loan-info__empty-message f-16">No Financing agreements yet.</div>
            )}
          </div>

          {data && data.insurance && (
            <div className="loan-info-insurance">
              <h2 className="f-26 f-500">Security deposit</h2>
              {(data && data.insurance) ? (
                <div className="loan-info-insurance__table">
                  <div className="loan-info-insurance__thead">
                    <div className="loan-info-insurance__thead-col">Total amount</div>
                    <div className="loan-info-insurance__thead-col">Outstanding</div>
                    <div className="loan-info-insurance__thead-col">Repaid back</div>
                  </div>

                  <div className="loan-info-insurance__tbody">
                    <div className="loan-info-insurance__tbody-row">
                      <div className="loan-info-insurance__tbody-col">{prettyFloatMoney(data.insurance.total_amount)}</div>
                      <div className="loan-info-insurance__tbody-col">{prettyFloatMoney(data.insurance.outstanding)}</div>
                      <div className="loan-info-insurance__tbody-col">{prettyFloatMoney(data.insurance.repaid_back)}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loan-info__empty-message f-16">No mutual insurance</div>
              )}
            </div>
          )}

          <div className="loan-info-payments">
            <h2 className="f-26 f-500">Repayment history</h2>

            {(paymentHistory.data && !!paymentHistory.data.list.length) ? (
              <div className="loan-info-payments__table">
                <div className="loan-info-payments__thead">
                  <div className="loan-info-payments__thead-col">Date</div>
                  <div className="loan-info-payments__thead-col">Amount</div>
                  <div className="loan-info-payments__thead-col">Status</div>
                </div>

                <div className="loan-info-payments__tbody">
                  {paymentHistory.data.list.map((item, idx) => (
                    <div className="loan-info-payments__tbody-row" key={idx}>
                      <div className="loan-info-payments__tbody-col">{moment(item.created_at).format(DATE_FORMAT_DD_MMM).replace('.', '')}</div>
                      <div className="loan-info-payments__tbody-col">{prettyFloatMoney(item.amount)}</div>
                      <div className="loan-info-payments__tbody-col loan-info-payments__description">{item.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="loan-info__empty-message f-16">
                No repayments yet.
                <MyLink href={APP_LINKS.prepayLoans}> Prepay loans →</MyLink>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default LoanInfo;
