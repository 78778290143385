import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import store, { history } from './store/configureStore';
import App from './App';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/styles/theme.scss';
import './index.scss';

Modal.setAppElement('#root');

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
      <ToastContainer />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
