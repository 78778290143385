import React from 'react';
import {useDispatch} from 'react-redux';
import {convertDateToString} from '../../helpers/utils';
import {createEducation, deleteEducation, updateEducation} from '../../store/services/userServices';
import {getUserProfile} from '../../store/actions/profileActions';
import ProfileEducationForm from "../../components/Forms/ProfileEducationForm";

const ProfileEducationModal = ({ onClose, education }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      const payload = {
        college: values.college,
        degree: values.degree,
        start_date: convertDateToString(values.start_date),
        end_date: convertDateToString(values.end_date),
        is_studying: !values.end_date,
      };

      const res = education
        ? await updateEducation(education.id, payload)
        : await createEducation(payload);

      if (res && res.success) {
        dispatch(getUserProfile());
        onClose();
      }
    } catch (e) {}
  }

  const onRemove = async () => {
    try {
      if (education) {
        const res = await deleteEducation({educations: [education.id]});
        if (res && res.success) {
          dispatch(getUserProfile());
          onClose();
        }
      }
    } catch (e) {}
  }

  return (
    <ProfileEducationForm
      education={education}
      onSubmit={onSubmit}
      onRemove={onRemove}
      onCancel={onClose}
    />
  );
};

export default ProfileEducationModal;