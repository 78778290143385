import axios from '../../axios-api';
import moment from 'moment';
import {push} from 'react-router-redux';
import Notify from '../../components/Notification';
import {MESSAGES} from '../../helpers/toaster';
import Pathes from '../../helpers/pathes';
import {getMessage, getShortUserInfo, shortenUserInfo} from '../../helpers/utils';
import {setUserProfile} from './profileActions';
import {CONTACT_EMAIL} from '../../helpers/constants';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER, SET_USER, SET_USER_LOCATION, UPDATE_USER
} from './actionTypes';

const registerUserRequest = () => ({ type: REGISTER_USER.REQUEST });
const registerUserSuccess = payload => ({type: REGISTER_USER.SUCCESS, payload});
const registerUserFailure = error => ({type: REGISTER_USER.FAILURE, error});

const loginUserRequest = () => ({ type: LOGIN_USER.REQUEST });
const loginUserSuccess = payload => ({ type: LOGIN_USER.SUCCESS, payload });
const loginUserFailure = error => ({ type: LOGIN_USER.FAILURE, error });

const setUser = user => ({type: SET_USER, user: getShortUserInfo(user)});

export const registerUser = userData => {
  return dispatch => {
    dispatch(registerUserRequest());
    return axios.post(Pathes.User.registration, userData).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);

        if (status === 200 || status === 201) {
          dispatch(registerUserSuccess({
            token: data.key,
            user: getShortUserInfo(data.user)
          }));
          Notify.success({text: 'Account created'});
          return {data, success: true};
        }

        if (data && data.message === "User with this email already registered") {
          sessionStorage.setItem('existingEmail', userData.email);
          dispatch(loginUser({email: userData.email, password: userData.password})).then(response => {
            (response && response.success) ? dispatch(push('/dashboard')) : dispatch(push('/sign-in'));
          });
          return {success: false};
        }

        Notify.error({text: message, status});
        throw new Error(message);
      }).catch(e => dispatch(registerUserFailure(e.message)));
  };
};

export const loginUser = data => {
  return dispatch => {
    dispatch(loginUserRequest());
    return axios.post(Pathes.Auth.login, data).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(loginUserSuccess({
            expireDate: data.expiration_time ? moment().add(data.expiration_time, 'minutes').toISOString() : null,
            token: data.key,
            user: getShortUserInfo(data.user)
          }));
          window.gtag && window.gtag('event', 'send', {'event_category' : 'form', 'event_label': 'founder_login'});
          return dispatch(push('/'));
        }
        message && !message.includes(CONTACT_EMAIL) && Notify.error({ text: message });
        throw new Error(message)
      },
    ).catch(e => {dispatch(loginUserFailure(e.message))})
  }
};

export const logoutUser = () => {
  return dispatch => {
    dispatch({type: LOGOUT_USER});
    dispatch(push('/sign-in'));
  };
};

export const updateUser = userData => {
  return (dispatch, getState) => {
    const prevData = getState().userStore.user;
    if (prevData) {
      dispatch(({type: UPDATE_USER, user: shortenUserInfo(userData, prevData)}));
    }
  };
};

export const contactInfoUpdate = payload => {
  return dispatch => {
    return axios.post(Pathes.User.contactInfoStepTwo, payload).then(
      response => {
        const {status, data} = response;
        if (status === 200) {
          dispatch(setUser(data.user));
          dispatch(setUserProfile(data));
          Notify.success({text: 'Contact information updated successfully'});
          return {data, success: true};
        }

        if (status === 422 && data.errors === "company_already_registered") {
          Notify.error({text: data.message});
          return {data, success: false, errors: {company_name: data.message}};
        }
        Notify.error({text: "Could not update contact information", status});
      });
  }
};

export const getUserLocation = () => {
  return async dispatch => {
    try {
      const response = await axios.get(Pathes.User.myLocation);
      if (response && response.status === 200) {
        const {data} = response;
        const location = {
          city: data.city,
          country: data.country_name,
          countryCode: data.country_code,
          postal: data.postal,
          state: data.state,
          ip: data.IPv4,
          lat: data.latitude,
          lon: data.longitude,
        }

        dispatch({type: SET_USER_LOCATION, location});
      }
    } catch (e) {}
  }
}

export const forgotPassword = email => {
  return () => {
    return axios.post(Pathes.Auth.forgotPassword, email).then(
      (response) => {
        const {status} = response;
        if (status === 404) {
          Notify.error({ text: 'This email is not registered' })
        }

        if (status === 200) {
          Notify.success({ text: 'Please, check your email' })
          return {success: true}
        }
      }
    );
  };
};

export const verifyToken = token => {
  return axios.post(Pathes.Auth.verifyToken, token).then(r => r && r.data && r.data.token);
}

export const resetPassword = data => {
  return async dispatch => {
    try {
      const response = await axios.post(Pathes.Auth.resetPassword, data);
      if (response && response.status === 200) {
        Notify.success({ text: (response.data && response.data.message) || 'Password changed' })
        return dispatch(push('/sign-in'));
      }

      const message = (response && response.data && response.data.message) || MESSAGES.somethingwrong;
      throw new Error(message)
    } catch (e) {}
  };
};