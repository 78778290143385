import * as React from 'react';
import { Redirect } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import RecoverPasswordPage from './pages/RecoverPasswordPage';
import DevPage from './pages/DevPage';
import RootPage from './pages/RootPage';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import FundraisingPage from './pages/FundraisingPage';
import PaymentsPage from './pages/PaymentsPage';
import PaymentsNextPage from './pages/PaymentsNextPage';
import PaymentsPrepayPage from './pages/PaymentsPrepayPage';
import PaymentsAddFundsPage from './pages/PaymentsAddFundsPage';
import PaymentsWithdrawPage from './pages/PaymentsWithdrawPage';
import FoundersPage from './pages/FoundersPage';
import FounderDetailPage from './pages/FounderDetailPage';
import LoansPage from './pages/LoansPage';
import ActivityPage from './pages/ActivityPage';
import ProfilePage from './pages/ProfilePage';
import HelpPage from './pages/HelpPage';
import LoanTermsPage from './pages/LoanTermsPage';
import LoanTermsOldPage from './pages/LoanTermsOldPage';
import SwapGuaranteePage from './pages/SwapGuaranteePage';
import TermsOfUsePage from './pages/TermsOfUsePage';

const ROUTES = [
  {
    path: '/sign-up/co-founder/:inviteCode',
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up Co-Founder',
    exact: true
  },
  {
    path: '/sign-up/:invite',
    component: SignUpPage,
    pageTitle: 'Scramble - Sign Up',
    exact: true
  },
  {
    path: '/sign-in',
    component: SignInPage,
    pageTitle: 'Scramble - Sign In',
    exact: true
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    pageTitle: 'Scramble - Forgot Password',
    exact: true
  },
  {
    path: '/password/set/:key',
    component: RecoverPasswordPage,
    pageTitle: 'Scramble - Password Recovery',
    exact: true
  },
  {
    path: '/legal/terms-of-use/',
    component: TermsOfUsePage,
    pageTitle: 'Scramble - Terms of Use',
    exact: true
  },
  {
    path: '/dev',
    component: DevPage,
    pageTitle: 'Scramble - Development Page',
    exact: true
  },
  {
    path: '/',
    component: RootPage,
    routes: [
      {
        path: '/',
        render: () => <Redirect to="/dashboard"/>,
        pageTitle: 'Scramble - Deposit Page',
        exact: true
      },
      {
        path: '/dashboard',
        component: DashboardPage,
        pageTitle: 'Scramble - Dashboard',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/fundraising',
        component: FundraisingPage,
        pageTitle: 'Scramble - Fundraising',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/founders',
        component: FoundersPage,
        pageTitle: 'Scramble - Founders',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/founders/:id',
        component: FounderDetailPage,
        pageTitle: 'Scramble - Founder Detail page',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/activity',
        component: ActivityPage,
        pageTitle: 'Scramble - Activity',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/profile',
        component: ProfilePage,
        pageTitle: 'Scramble - Profile',
        auth: user => !!user,
        exact: true
      },
      {
        path: '/payments',
        component: PaymentsPage,
        auth: user => !!user,
        pageTitle: 'Payments',
        routes: [
          {
            path: '/payments',
            render: () => <Redirect to="/payments/next-payments" />,
            pageTitle: 'Scramble - Next Payments Page',
            exact: true
          },
          {
            path: '/payments/next-payments',
            component: PaymentsNextPage,
            pageTitle: 'Scramble - Next Payments Page',
            exact: true
          },
          {
            path: '/payments/prepay-loans',
            component: PaymentsPrepayPage,
            pageTitle: 'Scramble - Prepay loans',
            exact: true
          },
          {
            path: '/payments/add-funds',
            component: PaymentsAddFundsPage,
            pageTitle: 'Scramble - Add Funds',
            exact: true
          },
          {
            path: '/payments/withdraw',
            component: PaymentsWithdrawPage,
            pageTitle: 'Scramble - Withdraw',
            exact: true
          }
        ]
      },
      {
        path: '/loans',
        component: LoansPage,
        auth: user => !!user,
        pageTitle: 'Scramble - Loans',
      },
      {
        path: '/help/loan-terms',
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true
      },
      {
        path: '/help/loan-terms/v1',
        component: LoanTermsOldPage,
        pageTitle: 'Scramble - Loan Terms Version 1',
        exact: true
      },
      {
        path: '/help/loan-terms/v2',
        component: LoanTermsPage,
        pageTitle: 'Scramble - Loan Terms',
        exact: true
      },
      {
        path: '/help/swap-guarantees',
        component: SwapGuaranteePage,
        pageTitle: 'Scramble - Mutual Swap Guarantee',
        exact: true
      },
      {
        path: '/help',
        component: HelpPage,
        auth: user => !!user,
        pageTitle: 'Scramble - FAQ',
        exact: true
      },
      {
        path: '**',
        component: PageNotFound,
        pageTitle: 'Not found'
      }
    ]
  },
];

export default ROUTES;
