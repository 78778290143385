import * as React from 'react';
import SignIn from '../../containers/SignIn';
import BasicLayout from '../../layouts/BasicLayout';

const SignInPage = ({history}) => (
  <BasicLayout>
    <div className="container">
      <SignIn history={history} />
    </div>
  </BasicLayout>
)

export default SignInPage;