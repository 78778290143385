import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as classnames from 'classnames';
import { ArrowUp } from '../UI/Icons';
import './index.scss';

const Question = ({ faq, data = {} }) => {
  const [show, showMenu] = useState(false);
  const { q: question, a: answer } = faq;
  const onHandleShowMenu = () => {
    showMenu(!show);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.hash === '#how-it-works') {
      onHandleShowMenu();
    }
  }, []);

  return (
    <div className={classnames('question', show && 'active')}>
      <div className="question__label" onClick={onHandleShowMenu}>
        <ArrowUp style={{ minWidth: '14px' }} />
        <p className="f-18">{question}</p>
      </div>
      <div className="question__content">
        <div className="question__content-inner f-16">{typeof answer === 'function' ? answer(data) : answer}</div>
      </div>
    </div>
  );
};

export default Question;
