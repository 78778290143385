import React from 'react';
import {connect} from 'react-redux';
import withScroll from '../../hoc/withScroll';
import Preloader from '../../components/UI/Preloader';
import FounderDetailCard from '../../components/Cards/FounderDetailCard';
import {getFounderDetail} from '../../store/services/userServices';
import {BackButton} from '../../components/UI/Buttons';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import {canGoBack} from '../../helpers/utils';
import './index.scss';

class FounderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      detail: null,
      loading: true,
    }
  }

  componentDidMount() {
    this.getDetails(this.state.id);
    this.props.getMissingProfileDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const founderID = this.props.match.params.id;
      this.setState(prevState => ({...prevState, id: founderID, loading: true}));
      this.getDetails(founderID);
      this.props.getMissingProfileDetails();
      const main = document.querySelector('main');
      main && main.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }

  getDetails = founderID => {
    this.setState(prevState => ({...prevState, loading: true}));
    getFounderDetail(founderID)
      .then(res => res && res.success
        ? this.setState(prevState => ({...prevState, detail: res.data, loading: false}))
        : this.setState(prevState => ({...prevState, detail: null, loading: false}))
      );
  }

  render() {
    const {detail, loading} = this.state;
    const {history} = this.props;

    return (
      <div className="founder-detail-page">
        <div className="container-inner">
          <div className="founder-detail-page__back">
            <BackButton onClick={() => canGoBack(history) ? history.goBack() : history.push('/founders')} />
          </div>
          {loading
            ? <Preloader className="founder-detail-page__preloader" />
            : <FounderDetailCard founderID={this.state.id} founder={detail} />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // detail: state.founderStore.detail,
  // fundraisingStatus: state.founderStore.fundraisingStatus,
  // swapPartners: state.founderStore.swapPartners,
})

const mapDispatchToProps = dispatch => ({
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  // getFounderSwapPartners: (id, params) => dispatch(getFounderSwapPartners(id, params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withScroll(FounderDetailPage));