export const GET_LOANS_LIST = {
  REQUEST: 'GET_LOANS_LIST_REQUEST',
  SUCCESS: 'GET_LOANS_LIST_SUCCESS',
  FAILURE: 'GET_LOANS_LIST_FAILURE',
}

export const GET_LOAN_SWAP_PARTNERS = {
  REQUEST: 'GET_LOAN_SWAP_PARTNERS_REQUEST',
  SUCCESS: 'GET_LOAN_SWAP_PARTNERS_SUCCESS',
  FAILURE: 'GET_LOAN_SWAP_PARTNERS_FAILURE',
}

export const GET_LOAN_DETAIL = {
  REQUEST: 'GET_LOAN_DETAIL_REQUEST',
  SUCCESS: 'GET_LOAN_DETAIL_SUCCESS',
  FAILURE: 'GET_LOAN_DETAIL_FAILURE',
}

export const GET_LOAN_TERMS = {
  REQUEST: 'GET_LOAN_TERMS_REQUEST',
  SUCCESS: 'GET_LOAN_TERMS_SUCCESS',
  FAILURE: 'GET_LOAN_TERMS_FAILURE',
}

export const GET_PAYMENT_HISTORY = {
  REQUEST: 'GET_PAYMENT_HISTORY_REQUEST',
  SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
  FAILURE: 'GET_PAYMENT_HISTORY_FAILURE',
}