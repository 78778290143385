import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage, getQuery} from '../../helpers/utils';
import Notify from '../../components/Notification';
import {
  CREATE_WITHDRAW_REQUEST,
  GET_NEXT_PAYMENT,
  GET_PAYMENT_SCHEDULE, GET_PREPAY_INFO, GET_WITHDRAW_HISTORY,
  GET_WITHDRAW_INFO
} from '../types/paymentTypes';

export const getWithdrawInfoRequest = () => ({ type: GET_WITHDRAW_INFO.REQUEST });
export const getWithdrawInfoSuccess = payload => ({type: GET_WITHDRAW_INFO.SUCCESS, payload});
export const getWithdrawInfoFailure = error => ({type: GET_WITHDRAW_INFO.FAILURE, error});

export const getWithdrawHistoryRequest = () => ({ type: GET_WITHDRAW_HISTORY.REQUEST });
export const getWithdrawHistorySuccess = payload => ({type: GET_WITHDRAW_HISTORY.SUCCESS, payload});
export const getWithdrawHistoryFailure = error => ({type: GET_WITHDRAW_HISTORY.FAILURE, error});

export const getNextPaymentRequest = () => ({ type: GET_NEXT_PAYMENT.REQUEST });
export const getNextPaymentSuccess = payload => ({type: GET_NEXT_PAYMENT.SUCCESS, payload});
export const getNextPaymentFailure = error => ({type: GET_NEXT_PAYMENT.FAILURE, error});

export const getPaymentScheduleRequest = () => ({ type: GET_PAYMENT_SCHEDULE.REQUEST });
export const getPaymentScheduleSuccess = payload => ({type: GET_PAYMENT_SCHEDULE.SUCCESS, payload});
export const getPaymentScheduleFailure = error => ({type: GET_PAYMENT_SCHEDULE.FAILURE, error});

export const getPrepayInfoRequest = () => ({ type: GET_PREPAY_INFO.REQUEST });
export const getPrepayInfoSuccess = payload => ({type: GET_PREPAY_INFO.SUCCESS, payload});
export const getPrepayInfoFailure = error => ({type: GET_PREPAY_INFO.FAILURE, error});

export const getWithdrawInfo = () => {
  return dispatch => {
    dispatch(getWithdrawInfoRequest());
    return axios.get(Pathes.Payments.withdrawInfo).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getWithdrawInfoSuccess(data));
          return { data, success: true };
        }
        throw new Error(message)
      }).catch(e =>  dispatch(getWithdrawInfoFailure(e.message)));
  };
};

export const getWithdrawHistory = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getWithdrawHistoryRequest());
    return axios.get(Pathes.Payments.withdrawHistory + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().paymentStore.withdrawHistory.data;
          if (!isNext || !prevData) {
            dispatch(getWithdrawHistorySuccess(data));
            return { data, success: true, message};
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }

          dispatch(getWithdrawHistorySuccess(updatedData));
          return { data: updatedData, success: true, message};
        }
        throw new Error(message)
      }).catch(e => dispatch(getWithdrawHistoryFailure(e.message)));
  };
}

export const createWithdrawRequest = payload => {
  return (dispatch, getState) => {
    return axios.post(Pathes.Payments.withdrawReq, payload).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const current = getState().paymentStore.withdrawInfo.data;
          current && dispatch({ type: CREATE_WITHDRAW_REQUEST, data: { ...current, cash: data.cash}});
          Notify.success({ text: message });
          return { data, success: true };
        }

        throw new Error(message)
      }).catch(e => ({ error: e.message }));
  };
}

export const getNextPayment = () => {
  return dispatch => {
    dispatch(getNextPaymentRequest());
    return axios.get(Pathes.Payments.nextPayment).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getNextPaymentSuccess(data));
          return { data, success: true, message };
        }

        if (status === 404) {
          dispatch(getNextPaymentSuccess(null));
          return { data: null, success: true, message };
        }

        throw new Error(message)
      }).catch(e => dispatch(getNextPaymentFailure(e.message)));
  };
}

export const getPaymentSchedule = params => {
  return (dispatch, getState) => {
    dispatch(getPaymentScheduleRequest());
    return axios.get(Pathes.Payments.schedule + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().paymentStore.paymentSchedule.data;
          if (params.page <= 1 || !prevData) {
            dispatch(getPaymentScheduleSuccess(data));
            return { data, success: true, message };
          }
          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }
          dispatch(getPaymentScheduleSuccess(updatedData));
          return { data: updatedData, success: true, message};
        }

        throw new Error(message);
      }).catch(e => dispatch(getPaymentScheduleFailure(e.message)));
  };
}

export const getPrepayInfo = () => {
  return dispatch => {
    dispatch(getPrepayInfoRequest());
    return axios.get(Pathes.Payments.prepay).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getPrepayInfoSuccess(data));
          return { data, success: true, message };
        }

        throw new Error(message);
      }).catch(e => dispatch(getPrepayInfoFailure(e.message)));
  };
}

export const prepayLoan = amount => {
  return dispatch => {
    return axios.post(Pathes.Payments.doPrepay, { amount }).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          Notify.success({ text: message });
          dispatch(getPrepayInfoSuccess(data));
          return { data, success: true, message };
        }
        if (status === 422) { Notify.error({ text: message }); }
        throw new Error(message);
      }).catch(e => ({ error: e.message }));
  }
}