import React from 'react';
import LoanInfo from '../../containers/LoanInfo';

const LoanInfoModal = ({ selectedLoan, ...props }) => (
  <LoanInfo
    loan={selectedLoan}
    {...props}
  />
);

export default LoanInfoModal;
