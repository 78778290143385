import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import {DATE_FORMAT_DD_MMM_YYYY} from '../../helpers/common';
import {LOAN_TYPES} from "../../helpers/constants";
import {prettyFloatMoney} from '../../helpers/utils';
import {getLoanDetail, getLoansList, getPaymentHistory} from '../../store/actions/loanActions';
import {getNextPayment} from '../../store/actions/paymentActions';
import ProgressBar from '../../components/ProgressBar';
import Preloader from '../../components/UI/Preloader';
// import LoansFAQ from '../../components/FAQ/LoansFAQ';
import {RoundLink} from '../../components/UI/RoundLink';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import {setModals} from '../../store/actions/commonActions';
import {MODAL_TYPES} from '../../components/ModalProvider';
import './index.scss';

class LoansAllPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20
    }
  }

  componentDidMount() {
    this.props.getLoansList(this.state);
    this.props.getNextPayment();
    this.props.getMissingProfileDetails();
  }

  onLoanOpen = loan => {
    this.props.setModals({
      type: MODAL_TYPES.loan_info,
      selectedLoan: loan
    });
  }

  render() {
    const {nextPayment} = this.props;
    const {data, loading} = this.props.loansList;
    const hasNotPaidLoans = !nextPayment.loading && !!nextPayment.data;
    const isEmpty = !(data && data.list.length);

    return (
      <div className="loans-page">
        <div className="container-inner">
          <h1 className="loans-page__title f-500 f-42">Loans</h1>
          {(!isEmpty || hasNotPaidLoans) && (
            <div className="loans-page__links">
              <RoundLink label="Next repayment" path="/payments/next-payments" />
              <RoundLink label="Prepay loans" path="/payments/prepay-loans" fillBackground />
            </div>
          )}

          {loading
            ? <Preloader />
            : isEmpty
              ? <div className="loans-page__empty f16">No loans yet.</div>
              : (
                <div className="loans-page__table">
                  <div className={classnames("loans-page__thead", data?.loans_type)}>
                    <div className="loans-page__thead-col">Origination date</div>
                    <div className="loans-page__thead-col">Loan amount</div>
                    <div className="loans-page__thead-col">Repayment progress</div>
                    <div className="loans-page__thead-col">Outstanding</div>
                    {[LOAN_TYPES.new, LOAN_TYPES.mixed].includes(data?.loans_type) && <div className="loans-page__thead-col">Fee for each 6 months</div>}
                    {[LOAN_TYPES.old, LOAN_TYPES.mixed].includes(data?.loans_type) && (
                      <>
                        <div className="loans-page__thead-col">Success fee</div>
                        <div className="loans-page__thead-col">Security deposit</div>
                      </>
                    )}
                  </div>

                  <div className="loans-page__tbody">
                    {data?.list.map(loan => (
                      <div className={classnames("loans-page__tbody-row", data?.loans_type)} key={loan.fundraising_id} onClick={() => this.onLoanOpen(loan)}>
                        <div className="loans-page__tbody-col f-18 f-500">
                          {moment(loan.end_date).format(DATE_FORMAT_DD_MMM_YYYY).replace(',', '')}
                        </div>
                        <div className="loans-page__tbody-col f-26 tl">
                          <span className="f-26 f-500">{prettyFloatMoney(loan.amounts.total_loan_amount)}</span>
                        </div>
                        <div className="loans-page__tbody-col">
                          <ProgressBar value={loan.payment_progress} />
                        </div>
                        <div className="loans-page__tbody-col f-18 f-500 tl">
                          {prettyFloatMoney(loan.amounts.outstanding)}
                        </div>
                        {[LOAN_TYPES.new, LOAN_TYPES.mixed].includes(data?.loans_type) && (
                          <div className="loans-page__tbody-col f-26 tl">
                            <span className="f-26 f-500">{loan.amounts.six_fee ? prettyFloatMoney(loan.amounts.six_fee) : '—'}</span>
                          </div>
                        )}
                        {[LOAN_TYPES.old, LOAN_TYPES.mixed].includes(data?.loans_type) && (
                          <>
                            <div className="loans-page__tbody-col f-26 tl">
                              <span className="f-26 f-500">{loan.amounts.success_fee ? prettyFloatMoney(loan.amounts.success_fee) : '—'}</span>
                            </div>
                            <div className="loans-page__tbody-col f-26 tl">
                              <span className="f-26 f-500">{loan.amounts.deposit ? prettyFloatMoney(loan.amounts.deposit) : '—'}</span>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/*<LoansFAQ />*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loansList: state.loanStore.loansList,
  loanDetail: state.loanStore.loanDetail,
  paymentHistory: state.loanStore.paymentHistory,
  nextPayment: state.paymentStore.nextPayment,
});

const mapDispatchToProps = dispatch => ({
  getLoansList: (params, isNext) => dispatch(getLoansList(params, isNext)),
  getLoanDetail: id => dispatch(getLoanDetail(id)),
  getPaymentHistory: (id, params, isNext) => dispatch(getPaymentHistory(id, params, isNext)),
  getNextPayment: () => dispatch(getNextPayment()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
  setModals: value => dispatch(setModals(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoansAllPage);