import * as React from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';
import {InputTextField} from '../../UI/InputTextField';
import {PasswordField} from '../../UI/PasswordField';
import {RoundButton} from '../../UI/RoundButton';
import {CONTACT_EMAIL} from '../../../helpers/constants';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
})

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.existingEmail = sessionStorage.getItem('existingEmail');
  }

  componentWillUnmount() {
    sessionStorage.setItem('existingEmail', '');
  }

  render() {
    const {error, onSubmit} = this.props;
    return (
      <Formik
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          email: this.existingEmail ? this.existingEmail : '',
          password: ''
        }}
      >
        {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <h1 className="login-form__title f-500">Sign in as founder</h1>
            <InputTextField
              label="Email *"
              name="email"
              value={values.email}
              onChange={e => setFieldValue('email', e.target.value.trim())}
              onClear={() => setFieldValue('email', '')}
              className="login-form__email"
              error={errors.email && touched.email && errors.email}
            />
            <PasswordField
              value={values.password}
              onChange={handleChange}
              onClear={() => setFieldValue('password', '')}
              error={errors.password && touched.password && errors.password}
              className="login-form__password"
            />
            {error && typeof error === "string" && error.includes(CONTACT_EMAIL) && (
              <p className="login-form__error f-16 f-500">
                There was suspicious activity on your profile and we have blocked it. <br />
                Please, contact us at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
              </p>
            )}
            <RoundButton
              label="Login"
              className="login-form__submit"
              type="submit"
              onSubmit={handleSubmit}
              fillBackground
              fullWidth
              withArrow
            />
            <Link to="/forgot-password" className="login-form__link" >Forgot password?</Link>
            {/*<div className="login-form__bottom">*/}
            {/*  <span>Not a member ?</span>*/}
            {/*  <RoundLink label="Sign up" path="/sign-up" />*/}
            {/*</div>*/}
          </form>
        )}
      </Formik>
    )
  }
}

export default LoginForm;
