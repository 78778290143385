import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProfileContactsForm from "../../components/Forms/ProfileContactsForm";
import {updateProfileContact} from "../../store/services/userServices";
import {getUserProfile} from "../../store/actions/profileActions";

const ProfileContactsModal = ({ onClose }) => {
  const { user } = useSelector(state => state.profileStore.profile.data);
  const { userLocation } = useSelector(state => state.userStore);
  const dispatch = useDispatch();

  const onSubmit = values => {
    const payload = {
      phone: values.phone,
      passport_id: values.passport_id,
      country: values.country,
      city: values.city,
      country_code: values.country_code,
      address: values.address,
      postal_code: values.postal_code,
    };

    updateProfileContact(payload)
      .then(res => {
        if (res && res.success) {
          dispatch(getUserProfile());
          onClose();
        }
      });
  }

  return (
    <ProfileContactsForm
      user={user}
      userLocation={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ProfileContactsModal;