import * as React from 'react'
import RecoverPassword from '../../containers/RecoverPassword';
import BasicLayout from '../../layouts/BasicLayout';

const RecoverPasswordPage = ({match}) => (
  <BasicLayout>
    <div className="container">
      <RecoverPassword token={match.params.key} />
    </div>
  </BasicLayout>
)

export default RecoverPasswordPage;