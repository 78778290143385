import React from 'react';
import {connect} from 'react-redux';
import {RoundLink} from '../../components/UI/RoundLink';
import PaymentBlock from '../../components/PaymentBlock';
import {getNextPayment} from '../../store/actions/paymentActions';
// import RepaymentNextFAQ from '../../components/FAQ/FundsNextRepaymentFAQ';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import PaymentScheduleTable from './PaymentScheduleTable';
import {APP_LINKS} from '../../helpers/links';
import './index.scss';

class PaymentsNextPage extends React.Component {
  componentDidMount() {
    this.props.getNextPayment();
    this.props.getMissingProfileDetails();
  }

  render() {
    const { nextPayment, balance } = this.props;

    return (
      <div className="payments-next-page">
        <div className="row">
          <div>
            <h2 className="f-26 f-500">Your next repayment</h2>
            {nextPayment.data && (
              <p className="payments-next-page__desc f-16 f-400">Each month you must make at least monthly repayment towards each loan. All repayments are debited automatically. Make sure you have sufficient funds in your account for the next repayment.</p>
            )}
             </div>
          <RoundLink
            label="Add funds"
            path={APP_LINKS.addFunds}
            className="payments-next-page__link"
          />
        </div>
        {nextPayment.data ? (
          <PaymentBlock
            isMain
            payment={nextPayment.data}
            cash={balance && balance.cash}
            className="payments-next-page__main"
          />
        ) : (
          <div className="payments-next-page__no-payment">
            No repayments.
          </div>
        )}

        <PaymentScheduleTable />
        {/*<RepaymentNextFAQ />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  nextPayment: state.paymentStore.nextPayment,
  balance: state.dashboardStore.balance,
})

const mapDispatchToProps = dispatch => ({
  getNextPayment: () => dispatch(getNextPayment()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsNextPage);