import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage} from '../../helpers/utils';

export const cancelWithdrawRequest = reqID => {
  return axios.delete(Pathes.Payments.withdrawRequest(reqID)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}