import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProfileAboutForm from "../../components/Forms/ProfileAboutForm";
import {updateProfileAbout} from "../../store/services/userServices";
import {getUserProfile} from "../../store/actions/profileActions";

const ProfileAboutModal = ({ onClose }) => {
  const { description, user, social_networks } = useSelector(state => state.profileStore.profile.data);
  const { userLocation } = useSelector(state => state.userStore);
  const dispatch = useDispatch();

  const onSubmit = values => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      citizenship: values.citizenship,
      citizenship_code: values.citizenship_code,
      facebook: values.facebook || null,
      twitter: values.twitter || null,
      linkedin: values.linkedin || null,
      description: values.description,
    };

    updateProfileAbout(payload)
      .then(res => {
        if (res && res.success) {
          dispatch(getUserProfile());
          onClose();
        }
      });
  }

  return (
    <ProfileAboutForm
      description={description}
      user={{...user, ...social_networks}}
      userLocation={userLocation}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default ProfileAboutModal;