import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import {BankRequisite} from "../../../BankRequisite";
import {
  getFullname,
  padNumber,
  prettyFloatMoney,
  scrollTop,
} from '../../../../helpers/utils';
import s from './index.module.scss';

export const BankTransfer = ({ amount }) => {
  const { user } = useSelector(state => state.userStore);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <h1 className={classnames(s.bank_transfer__title, 'f-26 f-500')}>
        Add cash to your account via Bank transfer
      </h1>
      <div className={s.bank_transfer__total}>
        <div
          className={classnames(s.bank_transfer__total_label, 'f-16-24 f-400')}
        >
          Payment amount
        </div>
        <div
          className={classnames(s.bank_transfer__total_amount, 'f-42 f-500')}
        >
          {prettyFloatMoney(amount)}
        </div>
      </div>

      <p className={classnames(s.bank_transfer__desc, 'f-16 f-400')}>
        Transfer cash from your bank account. As soon as you receive cash into
        your Scramble account, we will send you an email.
      </p>

      <div className={s.bank_transfer__container}>
        <BankRequisite userID={user?.id} className={s.bank_transfer__req} />
        <div className={s.bank_transfer__req_desc}>
          <p>The currency of your Scramble account is EUR.</p>
          <p>
            Cash is accepted only from bank accounts that are in your name:{' '}
            {getFullname(user.first_name, user.last_name)}
          </p>
          <p>
            Your cash should be in your Scramble account in 1-3 business days.
          </p>
          <p>
            Don&apos;t forget to indicate your Founder ID number in payment
            details: <span className="f-500">{padNumber(user.id)}</span>
          </p>
        </div>
      </div>
    </>
  );
};
