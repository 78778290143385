import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import Notify from '../../components/Notification';
import {getMessage} from '../../helpers/utils';

export const getFounderDetail = id => {
  return axios.get(Pathes.Founders.detail(id)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return { data, success: true };
      }
      throw new Error(message)
    }).catch(e => ({ success: false, message: e.message }));
}

export const getUserProfile = () => {
  return axios.get(Pathes.User.profile).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateProfilePhoto = photo => {
  return axios.post(Pathes.User.updateProfilePhoto, {photo}).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: photo ? 'Photo updated' : 'Photo removed'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updatePersonalInfo = payload => {
  return axios.put(Pathes.User.updatePersonalInfo, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Information updated'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const createCareer = payload => {
  return axios.post(Pathes.User.careerCreate, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'New career added'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateCareer = (id, payload) => {
  return axios.put(Pathes.User.career((id)), payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Career updated'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const deleteCareers = list => {
  return axios.delete(Pathes.User.careerDelete, {data: list}).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Career removed'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const createEducation = payload => {
  return axios.post(Pathes.User.educationCreate, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'New education added'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateEducation = (id, payload) => {
  return axios.put(Pathes.User.education((id)), payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Education updated'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const deleteEducation = list => {
  return axios.delete(Pathes.User.educationDelete, {data: list}).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Education removed'})
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateProfileAbout = payload => {
  return axios.post(Pathes.User.updateProfileAbout, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Information updated'})
        return {data, success: true};
      }

      if (status === 406) {
        const {errors} = data;
        errors && Object.keys(errors)
          .map((key, index) =>
            index === 0 && Notify.error({text: `"${key}" ${errors[key]}`}))
      }

      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateProfileContact = payload => {
  return axios.post(Pathes.User.updateProfileContact, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: "Contacts updated successfully"})
        return {data, success: true};
      }

      if (status === 406) {
        const {errors} = data;
        errors && Object.keys(errors)
          .map((key, index) =>
            index === 0 && Notify.error({text: `"${key}" ${errors[key]}`}))
      }

      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const getStartupSectors = () => {
  return axios.get(Pathes.User.sectors).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updateCompany = payload => {
  return axios.put(Pathes.User.updateCompany, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Company updated'})
        return {data, success: true};
      }

      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const updatePromo = payload => {
  return axios.put(Pathes.User.updatePromo, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }

      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const changePassword = payload => {
  return axios.post(Pathes.Auth.changePassword, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: message});
        return {success: true};
      }
      Notify.error({ text: (data && data.errors && data.errors.non_field_errors[0]) || message || 'Could not change password'})
      throw new Error('Could not change password');
    }
  ).catch(e => ({success: false, message: e.message}))
};

// Invite code generation
export const generateCoFounderInviteCode = () => {
  return axios.post(Pathes.Auth.coFounderInvite).then(
    response => {
      const {status, data} = response;
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error('Could not generate invite link');
    }
  ).catch(e => ({success: false, message: e.message}))
};

// Validate invite code
export const validateCoFounderInviteCode = code => {
  return axios.post(Pathes.Auth.coFounderValidateInvite, {code}).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {success: true};
      }
      if (status > 401 && status < 500) {
        return {success: false, message};
      }
      throw new Error(message);
    }
  ).catch(() => ({success: false}));
};