import React from 'react';
import * as classnames from 'classnames';
import parseUrl from 'parse-url';
import {LINK_TYPES} from '../../helpers/constants';
import {deepLink} from './linkGenerator';
import {extractHostname} from "../../helpers/utils";

const Linker = ({type = LINK_TYPES.web, className, value}) => {
  let domain = '';
  const params = {
    target: "_blank",
    rel: "noopener noreferrer",
  }

  if (type === LINK_TYPES.phone) {
    params.href = `tel:${value}`;
  }

  if (type === LINK_TYPES.web) {
    let url = {
      resource: extractHostname(value),
      pathname: '',
      href: `//${value}`
    }
    try {
      url = parseUrl(value);
    } catch (e) {
      console.log(e.message);
    }
    domain = url.resource;
    params.href = deepLink(url);
  }

  if (type === LINK_TYPES.mail) {
    params.href = `mailto:${value}`;
  }

  return (
    <a {...params} className={classnames("linker", "tl", className)}>
      {domain || value}
    </a>
  );
};

export default Linker;