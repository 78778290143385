import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import qs from 'qs';
import { getUserLocation, logoutUser } from '../../store/actions/userActions';
import Profile from '../../containers/Profile';
import { RoundButton } from '../../components/UI/RoundButton';
import { getFounderFundraisingStatus } from '../../store/services/fundraisingServices';
import Preloader from '../../components/UI/Preloader';
import {getMissingProfileDetails, getUserProfile} from '../../store/actions/profileActions';
import { USER_STATUSES } from '../../helpers/constants';
import VerifyForm from '../../components/VerifyForm';
import {MODAL_TYPES} from "../../components/ModalProvider";
import {setModals} from "../../store/actions/commonActions";
import './index.scss';

const ProfilePage = () => {
  const [fundraising, setFundraising]  = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: profile, loading } = useSelector(state => state.profileStore.profile);

  useEffect(() => {
    dispatch(getUserLocation());
    dispatch(getMissingProfileDetails());
    getProfile();
  }, []);

  const getProfile = async () => {
    const res = await dispatch(getUserProfile());
    if (res && res.success) {
      if (res.data.user.status === USER_STATUSES.blocked) {
        dispatch(logoutUser());
        return;
      }

      getFounderFundraisingStatus(res.data.id)
        .then(res => res && res.success && setFundraising(res.data));

      const redirectAnchor = qs.parse(location.search.replace('?', '')).r;
      if (redirectAnchor && (['contacts', 'company', 'veriff'].includes(redirectAnchor))) {
        const el = document.getElementById(redirectAnchor);
        if (el) {
          el.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }
  }

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const onChangePassword = () => {
    dispatch(setModals({
      type: MODAL_TYPES.change_password
    }));
  };

  return (
    <div className="profile-page">
      <div className="container-inner">
        {profile ? (
          <>
            <Profile
              founder={profile}
              fundraising={fundraising}
              logoutUser={handleLogout}
            />

            <section className="profile-page__veriff-section" id="veriff">
              <h2 className="f-26 f-500">Verify your identity</h2>
              {profile.user.is_verified ? (
                <div className="profile-page__veriff-verified f-16 f-500">
                  <p>Your identity successfully verified.</p>
                  <div className="profile-page__veriff-company">
                    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.11502 12.5364L9.40229 23.1242L12.5745 17.7818H18.8046L12.5166 28.3713H6.28726L0 17.7818L3.11502 12.5364ZM19.8931 25.7318L25.9739 15.1426H19.8379L16.8248 9.8969H28.9873L32 15.143L25.9189 25.7321H19.8928L19.8931 25.7318ZM21.7736 5.24607H9.19905L12.3713 10.5885L9.25627 15.8346L2.969 5.2464L6.08403 0H18.6582L21.7732 5.2464L21.7736 5.24607Z" fill="#9E98A3" />
                    </svg>
                    <p>Identity verification is provided by Veriff’s highly trusted and secure solution</p>
                  </div>
                </div>
              ) : (
                <div className="profile-page__veriff">
                  <VerifyForm disableSkip />
                </div>
              )}
            </section>

            <section className="profile-page__password">
              <h2 className="f-26 f-500">Change password</h2>
              <RoundButton
                label="Change password"
                onClick={onChangePassword}
                className="profile-page__password-btn"
              />
            </section>
          </>
        ) : (
          loading
            ? <Preloader className="profile-page__preloader" />
            : <div className="profile-page__empty">Could not load profile</div>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
