import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage, getQuery} from '../../helpers/utils';
import Notify from '../../components/Notification';

export const selectSwapPartner = payload => {
  return axios.post(Pathes.Founders.selection, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      if (status === 422) {
        Notify.success({text: message});
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const bulkSelectSwapPartners = payload => {
  return axios.post(Pathes.Fundraising.bulkSwapSelect, payload).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({text: 'Successfully saved'})
        return {data, success: true};
      }

      Notify.error({text: 'Could not save changes'});
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const getFoundersPartners = (founderID, params) => {
  return axios.get(Pathes.Founders.swapPartners(founderID) + getQuery(params)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

export const getFounderFundraisingStatus = founderID => {
  return axios.get(Pathes.Founders.fundraisingStatus(founderID)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

// Non-zero swaps with founders from the latest fundraising
export const getAcceptedSwaps = params => {
  return axios.get(Pathes.Fundraising.successfulSwaps + getQuery(params)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

// List of founders who selected General insurance strategy
export const getFoundersByGeneralStrategy = params => {
  return axios.get(Pathes.Fundraising.generalFounders + getQuery(params)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}

// List of founders who selected Custom insurance strategy
export const getFoundersByCustomStrategy = params => {
  return axios.get(Pathes.Fundraising.customFounders + getQuery(params)).then(
    response => {
      const {status, data} = response;
      const message = getMessage(data);
      if (status === 200) {
        return {data, success: true};
      }
      throw new Error(message)
    }).catch(e => ({success: false, message: e.message}));
}