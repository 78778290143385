import React, { Component } from 'react';
import * as classnames from 'classnames';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { prettyFloatMoney } from '../../../helpers/utils';
import { getPaymentSchedule } from '../../../store/actions/paymentActions';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/common';
import './index.scss';

class PaymentScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      activeRow: null,
    };
  }

  componentDidMount() {
    const { page, limit } = this.state;
    this.props.getPaymentSchedule({ page, limit });
  }

  getNext = totalPage => {
    const { page, limit } = this.state;
    if (page < totalPage) {
      this.setState(prevState => ({
        ...prevState,
        page: page + 1,
      }));
      this.props.getPaymentSchedule({ page: page + 1, limit });
    }
  }

  handleToggle = index => {
    this.setState(prevState => ({
      ...prevState,
      activeRow: prevState.activeRow === index ? null : index,
    }));
  }

  render() {
    const { page, activeRow } = this.state;
    const { data } = this.props.paymentSchedule;

    return (
      <div className="payment-schedule-table">
        <h2 className="payment-schedule-table__title f-500 f-26">Repayment Schedule</h2>
        {(!data || (data && !data.list.length))
          ? <div className="payment-schedule-table__empty f16">No repayments.</div>
          : (
            <>
              <div className="payment-schedule-table__table">
                <div className="payment-schedule-table__thead">
                  <div className="payment-schedule-table__thead-col">Num</div>
                  <div className="payment-schedule-table__thead-col">Date</div>
                  <div className="payment-schedule-table__thead-col">Repayment</div>
                  <div className="payment-schedule-table__thead-col">Outstanding principal</div>
                </div>

                <div className="payment-schedule-table__tbody">
                  {data.list.map((item, index) => (
                    <div
                      className="payment-schedule-table__tbody-row"
                      key={item.deadline + Date()}
                      onClick={() => this.handleToggle(index)}
                    >
                      <div className="payment-schedule-table__tbody-col">{index + 1}</div>
                      <div className="payment-schedule-table__tbody-col">
                        {
                          moment(item.deadline).format(DATE_FORMAT_DD_MM_YYYY)
                        }
                      </div>
                      <div className="payment-schedule-table__tbody-col">
                        <div>
                          {prettyFloatMoney(item.total_amount)}
                        </div>

                        <div className={
                          classnames('payment-schedule-table__dropdown', index === activeRow && 'active')
                        }
                        >
                          {
                            item.interest > 0
                              ? (
                                <span>
                                  <p className="f-16 f-400">Interest</p>
                                  <p className="f-16 f-400">{prettyFloatMoney(item.interest)}</p>
                                </span>
                              )
                              : null
                          }
                          {
                            item.principal > 0
                              ? (
                                <span>
                                  <p className="f-16 f-400">Principal</p>
                                  <p className="f-16 f-400">{prettyFloatMoney(item.principal)}</p>
                                </span>
                              )
                              : null
                          }
                          {
                            item.success_fee > 0
                              ? (
                                <span>
                                  <p className="f-16 f-400">Success fee</p>
                                  <p className="f-16 f-400">{prettyFloatMoney(item.success_fee)}</p>
                                </span>
                              )
                              : null
                          }
                          {
                            item.flat_fee > 0
                              ? (
                                <span>
                                  <p className="f-16 f-400">Flat fee</p>
                                  <p className="f-16 f-400">{prettyFloatMoney(item.flat_fee)}</p>
                                </span>
                              )
                              : null
                          }
                          {
                            item.charge > 0
                              ? (
                                <span>
                                  <p className="f-16 f-400">Charge</p>
                                  <p className="f-16 f-400">{prettyFloatMoney(item.charge)}</p>
                                </span>
                              )
                              : null
                          }
                        </div>
                      </div>
                      <div className="payment-schedule-table__tbody-col">{prettyFloatMoney(item.outstanding)}</div>
                    </div>
                  ))}
                </div>
              </div>
              {page < data.total_pages && (
                <div className="payment-schedule-table__more">
                  <button type="button" onClick={() => this.getNext(data.total_pages)}>Load more</button>
                </div>
              )}
            </>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  paymentSchedule: state.paymentStore.paymentSchedule,
});

const mapDispatchToProps = dispatch => ({
  getPaymentSchedule: params => dispatch(getPaymentSchedule(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScheduleTable);
