export const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_+!@#\$%\^&\*])(?=.{8,})");
// export const MONEY_REGEX = /^(?:[$]|)[1-9]*[0-9]+(\.[0-9]{1,2})?$/g
export const MONEY_REGEX = /^(?:\$)?[1-9][0-9]*$/g
export const CYRILLIC_REGEX = /[\u0400-\u04FF]/;
export const WEBSITE_REGEX = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?(\/)?/;
export const FACEBOOK_LINK_REGEX = /^(https?:\/\/)?(www\.)?(facebook|fb).com/;
export const LINKEDIN_LINK_REGEX = /^(https?:\/\/)?(www\.)?(linkedin).com/;
export const TWITTER_LINK_REGEX = /^(https?:\/\/)?(www\.)?(twitter).com/;
export const DATE_FORMAT_DD_MMMM = 'DD MMMM';
export const DATE_FORMAT_D_MMMM = 'D MMMM';
export const DATE_FORMAT_D = 'D';
export const DATE_FORMAT_M = 'M';
export const DATE_FORMAT_DD_MMM = 'DD MMM.';
export const DATE_FORMAT_DD_MMMM_YYYY = 'DD MMMM, YYYY';
export const DATE_FORMAT_DD_MM_YYYY = 'DD.MM.YYYY';
export const DATE_FORMAT_DD_MMM_YYYY = 'DD MMM, YYYY';
export const DATE_FORMAT_MM_YYYY = 'MM/YYYY';
export const DATE_FORMAT_YYYY = 'YYYY';
export const DATE_REQUEST = 'YYYY-MM-DD';

export const CV_ALLOWED_FORMATS = [
  'application/pdf',
  'application/vnd.ms-word',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'text/html',
  'text/plain',
  'image/jpeg',
  'image/jpg',
  'application/vnd.oasis.opendocument.text'
];

export const PHOTO_ALLOWED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png'
]