import React from 'react';
import * as classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import {CYRILLIC_REGEX} from "../../../helpers/common";
import './index.scss';

const TextareaField = ({ label, name, value, minRows, onChange, error, limit, placeholder = ' ', disabled, readOnly, allowCyrillic, className }) => {
  const handleOnChange = e => {
    if (!allowCyrillic && CYRILLIC_REGEX.test(e.target.value)) {
      return;
    }
    onChange(e);
  };

  return (
    <div className={classnames("textarea-field__wrap", readOnly && "read-only", className)} >
      <div className="textarea-field__group">
        <TextareaAutosize
          name={name}
          value={value}
          onChange={handleOnChange}
          minRows={minRows || 10}
          placeholder={placeholder}
          className={classnames("textarea-field f-15", value && "textarea-field__filled", error && "textarea-field__error")}
          disabled={disabled}
        />
        <label className="textarea-field__label" htmlFor={name}>{label}</label>
        {!disabled && limit && (
          <div className="textarea-field__limit f-12">
            <span className={classnames("textarea-field__limit-start", value.length > limit && "textarea-field__limit-start-error")}>{value.length}</span>/<span className="textarea-field__limit-end">{limit}</span>
          </div>
        )}
      </div>
      {error && <div className={classnames("textarea-field__error-text f-14")}>{error}</div>}
    </div>
  );
};

export default TextareaField;