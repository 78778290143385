import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getActiveFoundersList} from '../../store/actions/founderActions';
import {RoundLink} from '../../components/UI/RoundLink';
import Preloader from '../../components/UI/Preloader';
import SwapPartner from '../../components/SwapPartner';
import {ROLES, SWAP_STATUSES} from '../../helpers/constants';

const FoundersActive = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userStore.user);
  const activeList = useSelector(state => state.founderStore.activeList);

  useEffect(() => {
    dispatch(getActiveFoundersList({ page: 1, limit: 40 }));
  }, []);

  const { data, loading } = activeList;
  const isEmpty = !!(data && !data.list.length);

  if (isEmpty) {
    return null;
  }

  return (
    <div className="founders-active">
      <h2 className="f-26 f-500">Active founders — started their fundraising</h2>
      {loading ? <Preloader className="founders-active__preloader" /> : isEmpty ? (
        <div className="founders-active__empty">
          <span className="founders-active__empty-title f-16 f-400">No active founders yet. Be the first!</span>
          {user.role === ROLES.founder && <div><RoundLink label="Set fundraising goal" path="/fundraising" /></div>}
        </div>
      ) : (
        <div className="founders-active__list">
          {data && data.list && data.list.map(founder => (
            founder ? <SwapPartner
              key={founder.id}
              founder={{
                ...founder,
                status: founder.status === SWAP_STATUSES.partner && founder.status
              }}
              active
            /> : null
          ))}
        </div>
      )}
    </div>
  );
}

export default FoundersActive;