export const GET_WITHDRAW_INFO = {
  REQUEST: 'GET_WITHDRAW_INFO_REQUEST',
  SUCCESS: 'GET_WITHDRAW_INFO_SUCCESS',
  FAILURE: 'GET_WITHDRAW_INFO_FAILURE',
};

export const GET_NEXT_PAYMENT = {
  REQUEST: 'GET_NEXT_PAYMENT_REQUEST',
  SUCCESS: 'GET_NEXT_PAYMENT_SUCCESS',
  FAILURE: 'GET_NEXT_PAYMENT_FAILURE',
};

export const GET_PAYMENT_SCHEDULE = {
  REQUEST: 'GET_PAYMENT_SCHEDULE_REQUEST',
  SUCCESS: 'GET_PAYMENT_SCHEDULE_SUCCESS',
  FAILURE: 'GET_PAYMENT_SCHEDULE_FAILURE',
};

export const GET_PREPAY_INFO = {
  REQUEST: 'GET_PREPAY_INFO_REQUEST',
  SUCCESS: 'GET_PREPAY_INFO_SUCCESS',
  FAILURE: 'GET_PREPAY_INFO_FAILURE',
};

export const GET_WITHDRAW_HISTORY = {
  REQUEST: 'GET_WITHDRAW_HISTORY_REQUEST',
  SUCCESS: 'GET_WITHDRAW_HISTORY_SUCCESS',
  FAILURE: 'GET_WITHDRAW_HISTORY_FAILURE',
};

export const CREATE_WITHDRAW_REQUEST = 'CREATE_WITHDRAW_REQUEST';