import {METADATA} from '../../helpers/metadata';
import {LOGOUT_USER} from '../actions/actionTypes';
import {GET_ACTIVITY_LIST} from '../types/activityTypes';

const initialState = {
  activityList: { ...METADATA.default, data: null },
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LIST.REQUEST:
      return { ...state, activityList: { ...state.activityList, ...METADATA.request }};
    case GET_ACTIVITY_LIST.SUCCESS:
      return { ...state, activityList: { ...METADATA.success, data: action.payload }};
    case GET_ACTIVITY_LIST.FAILURE:
      return { ...state, activityList: { ...state.activityList, ...METADATA.error, error: action.error }};

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default activityReducer;