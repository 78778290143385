import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {RoundButton} from '../../components/UI/RoundButton';
import {getAllFoundersList} from '../../store/actions/founderActions';
import Preloader from '../../components/UI/Preloader';
import SwapPartner from '../../components/SwapPartner';

class FoundersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 30
    }
  }

  componentDidMount() {
    this.props.getAllFoundersList(this.state);
  }

  getNext = totalPages => {
    if (this.state.page < totalPages) {
      const nextPage = this.state.page + 1
      this.props.getAllFoundersList({
        ...this.state,
        page: nextPage,
      }, true);

      return this.setState({ ...this.state, page: nextPage })
    }
  }

  render() {
    const { page } = this.state;
    const {allList, activeList} = this.props;
    const { data, loading } = allList;
    const isEmpty = !!(data && !data.list.length);
    const haveActiveFounders = !!(
      activeList.data && activeList.data.total_count > 0
    );

    return (
      <div className={classnames("founders-all", haveActiveFounders && "active")}>
        {haveActiveFounders && <h2 className="f-26 f-500">{data ? `All ${data.total_count} awesome founders` : 'All awesome founders'}</h2>}

        {(page === 1 && loading) ? <Preloader className="founders-all__preloader" /> : isEmpty ? (
          <div className="founders-all__empty">
            <span className="founders-all__empty-title f-16 f-400">No founders yet.</span>
          </div>
        ) : (
          <div className="founders-all__list">
            {data && data.list && data.list.map(founder => (
              founder ? <SwapPartner
                key={founder.id}
                founder={founder}
              /> : null
            ))}
          </div>
        )}

        {data && page < data.total_pages && (
          <RoundButton
            type="button"
            label="Show more founders"
            className="founders-all__btn"
            onClick={() => this.getNext(data.total_pages)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allList: state.founderStore.allList,
  activeList: state.founderStore.activeList,
})

const mapDispatchToProps = dispatch => ({
  getAllFoundersList: (params, isNext) => dispatch(getAllFoundersList(params, isNext)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FoundersAll);