import React from 'react';
import * as classnames from 'classnames';
import {XIcon} from '../UI/Icons';
import './index.scss';

const CVUpload = ({ value, name, label, onChange, onRemove, error, className }) => {
  return (
    <div className={classnames("cv-upload", className)}>
      {value ? (
        <React.Fragment>
          <p className="cv-upload__title f-12">{label}</p>
          <p className="cv-upload__uploaded tl">
            {value && value.name}
            <button
              type="button"
              onClick={onRemove}
              className="cv-upload__uploaded-remove"
            >
              <XIcon className="cv-upload__uploaded-icon" />
            </button>
          </p>
        </React.Fragment>
      ) : (
        <label htmlFor={name} className={classnames("cv-upload__label", error && "cv-upload__label-error")}>
          <div className="cv-upload__centered">
            <p className="cv-upload__centered-title f-16">{label}</p>
            {error ? <p className="cv-upload__centered-error f-12">{error}</p> : <p className="cv-upload__centered-desc f-12">Upload file up to 12Mb</p>}
          </div>
          <input
            type="file"
            name={name}
            id={name}
            onChange={onChange}
            className="cv-upload__input"
          />
        </label>
      )}
    </div>
  );
};

export default CVUpload;