import React from 'react';
import {Formik} from 'formik';
import * as classnames from 'classnames';
import Checkbox from '../../UI/Checkbox';
import {RoundButton} from '../../UI/RoundButton';
import {MyLink} from '../../MyLink';
import {APP_LINKS} from '../../../helpers/links';
import WarningBlock from '../../UI/WarningBlock';
import './index.scss';

const CoFounderFundraisingAcceptForm = ({status, className, disabled, onSubmit}) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        has_accepted_settings: status.is_confirmed,
        has_accepted_group_a_terms: status.has_accepted_group_a_terms,
        has_accepted_group_b_terms: status.has_accepted_group_b_terms,
      }}
    >
      {({values, setFieldValue, handleSubmit}) => (
        <form className={classnames("cofounder_fr_accept_form", className)} onSubmit={handleSubmit}>
          <Checkbox
            name="has_accepted_settings"
            value="has_accepted_settings"
            isChecked={values.has_accepted_settings}
            onChange={() => setFieldValue('has_accepted_settings', !values.has_accepted_settings)}
            className="cofounder_fr_accept_form__checkbox"
            disabled={disabled}
            render={() => (
              <div className="f-16 f-400">I have reviewed and agree with the fundraising settings for the current round.</div>
            )}
          />
          <Checkbox
            name="has_accepted_group_a_terms"
            value="has_accepted_group_a_terms"
            isChecked={values.has_accepted_group_a_terms}
            onChange={() => setFieldValue('has_accepted_group_a_terms', !values.has_accepted_group_a_terms)}
            className="cofounder_fr_accept_form__checkbox"
            disabled={disabled}
            render={() => (
              <div className={classnames("f-16 f-400")}>
                I have read the terms and conditions of the <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>Financing Agreement</MyLink> (Group A loans), and  <br />I agree to conclude it with the Investors as a result of the current Fundraising round.
              </div>
            )}
          />
          <Checkbox
            name="has_accepted_group_b_terms"
            value="has_accepted_group_b_terms"
            isChecked={values.has_accepted_group_b_terms}
            onChange={() => setFieldValue('has_accepted_group_b_terms', !values.has_accepted_group_b_terms)}
            className="cofounder_fr_accept_form__checkbox"
            disabled={disabled}
            render={() => (
              <div className={classnames("f-16 f-400")}>
                I have read the terms and conditions of the <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>Subordinated Financing Agreement</MyLink> (Group B loans), and <br />I agree to conclude it with the Investors as a result of the current Fundraising round.
              </div>
            )}
          />
          <div className="cofounder_fr_accept_form__submit">
            {disabled && (
              <WarningBlock
                className="cofounder_fr_accept_form__warning"
              >
                <div className="f-16 f-500">Verify your identity to confirm fundraising.</div>
              </WarningBlock>
            )}
            <RoundButton
              label="Save confirmation"
              type="button"
              fillBackground
              fullWidth
              disabled={disabled || !(values.has_accepted_settings && values.has_accepted_group_a_terms && values.has_accepted_group_b_terms)}
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CoFounderFundraisingAcceptForm;