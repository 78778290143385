export default class Pathes {
  static Auth = class {
    static login = '/auth/founder/login/';
    static forgotPassword = '/auth/forgotPassword/';
    static resetPassword = '/auth/resetPassword/';
    static verifyToken = '/auth/verifyToken/';
    static changePassword = '/auth/changePassword/';
    static coFounderInvite = `/auth/doGenerateInviteLink/`;
    static coFounderValidateInvite = `/auth/doValidateInviteCode/`;
  };

  static User = class {
    static registration = 'founders/registration/';
    static contactInfoStepTwo = '/founders/registration/step_two/';
    static profile = '/founders/profile/';
    static sectors = '/sectors/';
    static photo = '/users/doUpdatePhoto/';
    static updateProfilePhoto = '/users/doUpdateProfilePhoto/';
    static careerCreate = '/founders/careers/doCreateCareer/';
    static careerDelete = '/founders/careers/doDeleteCareers/';
    static educationCreate = '/founders/educations/doCreateEducation/';
    static educationDelete = '/founders/educations/doDeleteEducations/';
    static updateProfileAbout = '/founders/doUpdatePersonalInfo/';
    static updateProfileContact = '/founders/doUpdateContactInfo/';
    static updateCompany = '/companies/doUpdateCompany/';
    static updatePromo = '/companies/doUpdatePromo/';
    static profileMissingDetails = '/founders/missing_info/';
    static company = companyID => `/companies/${companyID}/`;
    static career = careerID => `/founders/careers/${careerID}/doUpdateCareer/`;
    static education = educationID => `/founders/educations/${educationID}/doUpdateEducation/`;
    static myLocation = '/my_location/';
  };

  static Fundraising = class {
    static status = 'founders/fundraising_status/';
    static loanGroups = 'fundraising/loan_groups/';
    static setGoal = 'founders/fundraising_goal/';
    static swapList = 'founders/accepted_swaps/';
    static successfulSwaps = 'founders/successful_swaps/';
    static resetGoal = 'founders/doResetGoal/';
    static partnerCounts = 'founders/partner_counts/';
    static generalFounders = 'strategy_founders/general/';
    static strategyCustomFounders = 'strategy_founders/custom/';
    static customPartners = 'founders/custom_partners/';
    static holdCustomPartners = 'founders/on_hold_selected_founders/';
    static latestFundraisingPartners = 'founders/latest_fundraising_partners/';
    static bulkSwapSelect = 'founders/bulk_select/';

    static nextStatus = 'founders/next_fundraising_status/';
    static accepted = 'founders/accepted_swaps/';
    static incoming = 'founders/incoming_swaps/';

    static rejected = 'founders/rejected_swaps/';
    static potential = 'founders/potential_partners/';
    static requestSwap = 'swaps/doSendSwapRequest/';
    static acceptSwap = id => `swaps/${id}/doAcceptSwapRequest/`;
    static rejectSwap = id => `swaps/${id}/doRejectSwapRequest/`;
    static cancelSwapRequest = id => `swaps/${id}/doCancelSwapRequest/`;
    static acceptOrDeclineFundraising = '/founders/doAcceptOrDeclineParticipatingStatus/';
  };

  static Common = class {
    static founderInfo = id => `/founders/${id}/`;
  };

  static Stripe = class {
    static session = '/create_checkout_session/';
    static methods = '/payment_methods_with_fees/';
    static calculate = '/calculate_total_amount/';
    static info = sessionID => `/payment_sessions/${sessionID}/`;
  };

  static File = class {
    static file = 'files/';
    static photo = 'images/';
  }

  static Payments = class {
    static withdrawInfo = '/withdraw_info/';
    static withdrawHistory = '/withdraw_requests/';
    static withdrawRequest = reqID => `/withdraw_requests/${reqID}/`;
    static withdrawReq = '/withdraw/';
    static nextPayment = '/next_payment/';
    static schedule = '/payment_schedule/';
    static prepay = '/prepay_info/';
    static doPrepay = '/loans/doPrepayLoan/';
  }

  static Dashboard = class {
    static fundraisingDates = '/founders/fundraising_dates/';
    static balance = '/founders/finances/';
    static balanceInfo = '/founders/dashboard/balance/';
    static paymentInfo = '/founders/dashboard/next_payment/';
    static depositInfo = '/founders/dashboard/deposit_totals/';
  }

  static Founders = class {
    static activeList = '/participating_founders/';
    static allList = '/inactive_founders/';
    static detail = id => `/founders/${id}/doGetFounderPageDetails/`;
    static fundraisingStatus = id => `/founders/${id}/fundraising_status/`;
    static swapPartners = id => `/founders/${id}/partners/`;
    static selection = '/founders/selection/';
    static coFounders = '/founders/co_founders/';
  };

  static Activity = class {
    static list = '/activities/';
  };

  static Loans = class {
    static list = '/founder/fundraisings/';
    static detail = id => `/founder/fundraisings/${id}/`;
    static swapPartners = '/loans/doGetLoanSwapPartners/';
    static loanTerms = '/loan_groups/founder_terms/';
    static paymentHistory = id => `/loan_payment_histories/${id}/`;
  };
}
