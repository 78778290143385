import {METADATA} from '../../helpers/metadata';
import {LOGOUT_USER} from '../actions/actionTypes';
import {
  GET_CO_FOUNDERS_LIST,
  GET_CUSTOM_STRATEGY_FOUNDERS, GET_CUSTOM_STRATEGY_PARTNERS,
  GET_FR_LOAN_GROUPS,
  GET_FR_STATUS,
  GET_FR_SWAP_LIST, GET_HOLD_CUSTOM_PARTNERS, GET_RESULT_CUSTOM_PARTNERS,
  SET_FUNDRAISING_STATUS,
  SET_PARTNER_COUNTS,
} from '../types/fundraisingTypes';

const initialState = {
  isParticipating: false,
  status: { ...METADATA.default, data: null },
  loanGroups: { ...METADATA.default, data: null },
  swapList: { ...METADATA.default, data: null },
  partnerCounts: {selected: 0, participating: 0, free: 0, confirmed: 0},
  customStrategyFounders: { ...METADATA.default, data: null },
  customStrategyPartners: { ...METADATA.default, data: null },
  holdCustomPartners: { ...METADATA.default, data: null },
  resultCustomPartners: { ...METADATA.default, data: null },
  coFounders: { ...METADATA.default, data: null },
};

const fundraisingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FR_STATUS.REQUEST:
      return { ...state, status: { ...METADATA.request, data: null }};
    case GET_FR_STATUS.SUCCESS:
      return { ...state, status: { ...METADATA.success, data: action.payload }, isParticipating: action.payload.is_participating};
    case GET_FR_STATUS.FAILURE:
      return { ...state, status: { ...state.status, ...METADATA.error, error: action.error }, isParticipating: false};
    case GET_FR_LOAN_GROUPS.REQUEST:
      return { ...state, loanGroups: { ...state.loanGroups, ...METADATA.request }};
    case GET_FR_LOAN_GROUPS.SUCCESS:
      return { ...state, loanGroups: { ...METADATA.success, data: action.payload }};
    case GET_FR_LOAN_GROUPS.FAILURE:
      return { ...state, loanGroups: { ...state.loanGroups, ...METADATA.error, error: action.error }};
    case GET_FR_SWAP_LIST.REQUEST:
      return { ...state, swapList: { ...state.swapList, ...METADATA.request, data: null }};
    case GET_FR_SWAP_LIST.SUCCESS:
      return { ...state, swapList: { ...METADATA.success, data: action.payload }};
    case GET_FR_SWAP_LIST.FAILURE:
      return { ...state, swapList: { ...state.swapList, ...METADATA.error, error: action.error }};
    case SET_PARTNER_COUNTS:
      return { ...state, partnerCounts: action.counts };
    case SET_FUNDRAISING_STATUS:
      return { ...state, status: {...state.status, data: action.status}, isParticipating: action.status?.is_participating}
    case GET_CUSTOM_STRATEGY_FOUNDERS.REQUEST:
      return { ...state, customStrategyFounders: { ...state.customStrategyFounders, ...METADATA.request }};
    case GET_CUSTOM_STRATEGY_FOUNDERS.SUCCESS:
      return { ...state, customStrategyFounders: { ...METADATA.success, data: action.payload }};
    case GET_CUSTOM_STRATEGY_FOUNDERS.FAILURE:
      return { ...state, customStrategyFounders: { ...state.customStrategyFounders, ...METADATA.error, error: action.error }};
    case GET_CUSTOM_STRATEGY_PARTNERS.REQUEST:
      return { ...state, customStrategyPartners: { ...state.customStrategyPartners, ...METADATA.request }};
    case GET_CUSTOM_STRATEGY_PARTNERS.SUCCESS:
      return { ...state, customStrategyPartners: { ...METADATA.success, data: action.payload }};
    case GET_CUSTOM_STRATEGY_PARTNERS.FAILURE:
      return { ...state, customStrategyPartners: { ...state.customStrategyPartners, ...METADATA.error, error: action.error }};
    case GET_RESULT_CUSTOM_PARTNERS.REQUEST:
      return { ...state, resultCustomPartners: { ...state.resultCustomPartners, ...METADATA.request }};
    case GET_RESULT_CUSTOM_PARTNERS.SUCCESS:
      return { ...state, resultCustomPartners: { ...METADATA.success, data: action.payload }};
    case GET_RESULT_CUSTOM_PARTNERS.FAILURE:
      return { ...state, resultCustomPartners: { ...state.resultCustomPartners, ...METADATA.error, error: action.error }};
    case GET_HOLD_CUSTOM_PARTNERS.REQUEST:
      return { ...state, holdCustomPartners: { ...state.holdCustomPartners, ...METADATA.request }};
    case GET_HOLD_CUSTOM_PARTNERS.SUCCESS:
      return { ...state, holdCustomPartners: { ...METADATA.success, data: action.payload }};
    case GET_HOLD_CUSTOM_PARTNERS.FAILURE:
      return { ...state, holdCustomPartners: { ...state.holdCustomPartners, ...METADATA.error, error: action.error }};
    case GET_CO_FOUNDERS_LIST.REQUEST:
      return { ...state, coFounders: { ...state.coFounders, ...METADATA.request }};
    case GET_CO_FOUNDERS_LIST.SUCCESS:
      return { ...state, coFounders: { ...METADATA.success, data: action.payload }};
    case GET_CO_FOUNDERS_LIST.FAILURE:
      return { ...state, coFounders: { ...METADATA.error, data: null, error: action.error }};

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default fundraisingReducer;