import React from 'react';
import moment from 'moment';
import nl2br from 'react-nl2br';
import config from '../../../config';
import Avatar from '../../../components/UI/Avatar';
import {
  getFullname,
  getNameInitials,
  prettyFloatMoney,
  prettyMoney,
} from '../../../helpers/utils';
import {DocumentIcon, FacebookIcon, LinkedInIcon, TwitterIcon} from '../../UI/Icons';
import TruncatedTextByLength from '../../../components/UI/TruncatedTextByLength';
import {DATE_FORMAT_MM_YYYY, DATE_FORMAT_YYYY} from '../../../helpers/common';
import {getFounderFundraisingStatus} from '../../../store/services/fundraisingServices';
import Linker from '../../Linker';
import {Link} from 'react-router-dom';
import './index.scss';

class FounderDetailCard extends React.Component {
  state = {
    fundraising: null,
  }

  componentDidMount() {
    const {founderID} = this.props;
    getFounderFundraisingStatus(founderID)
      .then(res => res && res.success && this.setState(prevState => ({...prevState, fundraising: res.data})));
  }

  render() {
    const {fundraising} = this.state;
    const {founder} = this.props;

    if (!founder) {
      return null;
    }

    const fullName = getFullname(founder.user.first_name, founder.user.last_name);
    const {user, company, description, social_networks} = founder;
    const isAboutEmpty = !description && !user.country && !user.date_of_birth;
    const isEmptyCompany = !company.is_created_by_user || !company.name;

    return (
      <div className="founder-detail-card">
        <div className="founder-detail-card__header">
          <Avatar
            size={260}
            alt="avatar"
            src={user.photo && user.photo.file}
            nameInitials={getNameInitials(user.first_name, user.last_name)}
            active={!!fundraising}
            className="founder-detail-card__header-avatar"
          />
          <div className="founder-detail-card__header-info">
            <div className="founder-detail-card__header-top">
              <div className="founder-detail-card__header-top-inner">
                <h1 className="founder-detail-card__header-name f-42 f-500 line2tl">{fullName}</h1>
                <p className="founder-detail-card__header-company f-16 f-400 tl">{company.name || '-'}</p>
              </div>
              <div className="founder-detail-card__header-socials">
                {Object.keys(social_networks).filter(key => key !== 'id').map((key, idx) => {
                  if (key === 'facebook' && social_networks.facebook) {
                    return <FacebookIcon key={idx} href={social_networks.facebook} />
                  }
                  if (key === 'linkedin' && social_networks.linkedin) {
                    return  <LinkedInIcon key={idx} href={social_networks.linkedin} />
                  }
                  if (key === 'twitter' && social_networks.twitter) {
                    return <TwitterIcon key={idx} href={social_networks.twitter}  />
                  }
                  return null
                })}
              </div>
            </div>

            {!!founder.income_forecast.end && (
              <div className="founder-detail-card__header-income">
                <div className="f-16 f-400">Income forecast</div>
                <div className="f-26 f-500">
                  {`up to ${prettyFloatMoney(founder.income_forecast.end)}`}
                </div>
              </div>
            )}

            {fundraising && (
              <div className="founder-detail-card__header-fundraising">
                <div className="founder-detail-card__header-goal">
                  <div className="f-16 f-400">Fundraising goal</div>
                  <div className="f-26 f-500">{prettyMoney(fundraising.goal)}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="founder-detail-card__personal-wrap">
          <h2 className="f-32 f-500 founder-detail-card__title">Personal Info</h2>

          <div className="founder-detail-card__personal">
            {!isAboutEmpty ? (
              <>
                <div className="founder-detail-card__personal-about">
                  <h3 className="f-22 f-500">About founder</h3>
                  <div className="founder-detail-card__personal-about-list">
                    {user && user.country && <div className="f-16 f-400 tl bullet">Citizen of {user.country}</div>}
                    {user && user.date_of_birth && <div className="f-16 f-400 tl bullet">{moment().diff(moment(user.date_of_birth), 'years')} years old</div>}
                  </div>
                </div>

                {description && (
                  <div className="profile__personal-summary">
                    <TruncatedTextByLength
                      text={nl2br(description)}
                      className="profile__personal-summary-text f-16"
                      max={1000}
                    />
                  </div>
                )}
              </>
            ) : <div className="founder-detail-card__personal-empty f-16 f-400">Founder did not provide details</div>}

            {founder.careers && (
              <div className="founder-detail-card__career">
                <h3 className="f-22 f-500">Career</h3>
                <ul className="f-16 founder-detail-card__career-list">
                  {founder.careers.map((item, idx) => (
                    <li key={idx} className="f-16">
                      <div className="bullet">{moment(item.start_date).format(DATE_FORMAT_MM_YYYY)} {!item.is_working ? ` – ${moment(item.end_date).format(DATE_FORMAT_MM_YYYY)}` : ' - present'}</div>
                      <div>{[item.position, item.company].filter(item => item).join(' at ')}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {founder.educations && (
              <div className="founder-detail-card__education">
                <h3 className="f-22 f-500">Education</h3>
                <ul className="f-16 founder-detail-card__education-list">
                  {founder.educations.map((item, idx) => (
                    <li key={idx} className="f-16">
                      <div className="bullet">{moment(item.start_date).format(DATE_FORMAT_YYYY)} {!item.is_studying ? ` – ${moment(item.end_date).format(DATE_FORMAT_YYYY)}` : ' - present'}</div>
                      <div>{[item.degree, item.college].filter(item => item).join(', ')}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {founder.cv && (
              <a href={`${config.apiURL}files/${founder.cv.id}/`}
                 className="founder-detail-card__document"
                 target="_blank" rel="noopener noreferrer"
              >
                <DocumentIcon />
                <span className="f-16 f-500">Download full CV (pdf)</span>
              </a>
            )}
          </div>
        </div>

        {founder.recommendations && (
          <div className="founder-detail-card__recommendations-wrap">
            <h2 className="f-32 f-500 founder-detail-card__title">Recommended by these professionals</h2>
            <div className="founder-detail-card__recommendations">
              {founder.recommendations.map(rec => (
                <div className="founder-detail-card__review" key={rec.id}>
                  <Avatar
                    size={60}
                    alt="avatar"
                    src={rec.photo && rec.photo.file}
                    nameInitials={getNameInitials(rec.first_name, rec.last_name)}
                    className="founder-detail-card__review-avatar"
                  />
                  <div className="founder-detail-card__review-content">
                    <div className="founder-detail-card__review-header">
                      <h6 className="f-22 f-500">{getFullname(rec.first_name, rec.last_name)}</h6>
                      <div className="founder-detail-card__review-socials">
                        {rec.facebook && <FacebookIcon href={rec.facebook} />}
                        {rec.linkedin && <LinkedInIcon href={rec.linkedin} />}
                      </div>
                    </div>
                    {(rec.position || rec.company) && (
                      <p className="f-16">
                        {!rec.position && rec.company && rec.company}
                        {rec.position && !rec.company && rec.position}
                        {rec.position && rec.company && `${rec.position} at ${rec.company}`}
                      </p>
                    )}
                    <div className="f-16 founder-detail-card__review-comment">
                      ”{rec.text}”
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="founder-detail-card__startup-wrap">
          <h2 className="f-32 f-500 founder-detail-card__title">Company details</h2>
          {!isEmptyCompany ? (
            <div className="founder-detail-card__startup">
              <div className="founder-detail-card__startup-content">
                <div className="founder-detail-card__startup-header">
                  {company.image && (
                    company.site ? (
                      <a href={company.site} className="founder-detail-card__startup-logo" target="_blank" rel="noopener noreferrer">
                        <img src={company.image.file} alt={company.name || 'Startup Logo'} />
                      </a>
                    ) : (
                      <div className="founder-detail-card__startup-logo">
                        <img src={company.image.file} alt={company.name || 'Startup Logo'} />
                      </div>
                    )
                  )}
                  {company.name && (
                    <div className="f-22 f-500 founder-detail-card__startup-position">
                      {company.name}
                    </div>
                  )}
                  <div className="f-16 founder-detail-card__startup-info">
                    {company.sectors && company.sectors.map(sector => (
                      <span key={sector.id}>{sector.name}</span>
                    ))}
                    {company.country && <span>{company.country} based</span>}
                    {company.foundation_date && <span>Started in&nbsp;{moment(company.foundation_date).format('YYYY')}</span>}
                  </div>
                  {company.site && (
                    <Linker
                      value={company.site}
                      className="f-16 f-400"
                    />
                  )}
                </div>

                {!!company?.co_founders?.length && (
                  <div className="profile__startup-founders">
                    {company.co_founders.map(founder => {
                      return (
                        <Link to={`/founders/${founder.id}`} className="profile__startup-founder" key={founder.id}>
                          <Avatar
                            size={50}
                            src={founder.avatar.file}
                            alt={founder.full_name}
                            nameInitials={getNameInitials(founder.first_name, founder.last_name)}
                          />
                          <div className="f-18 f-500 tl">{founder.full_name}</div>
                          <div className="profile__startup-founder-role f-14 f-400">Co-founder</div>
                        </Link>
                      )
                    })}
                  </div>
                )}

                {company.description && (
                  <div className="founder-detail-card__startup-desc">
                    <TruncatedTextByLength
                      text={nl2br(company.description)}
                      className="f-16"
                    />
                  </div>
                )}
                {company.promo && company.promo.image && (
                  <div className="founder-detail-card__startup-promo">
                    <a href={company.site || '#'} target="_blank" rel="noopener noreferrer">
                      <img
                        src={company.promo.image.file}
                        alt={company.promo.image.file.name}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="founder-detail-card__startup-empty f-16 f-400">Founder did not provide startup details</div>
          )}
        </div>
      </div>
    );
  }
}

export default FounderDetailCard;