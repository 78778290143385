import * as React from 'react';
import {connect} from 'react-redux';
import { renderRoutes } from 'react-router-config';
import Navigation from '../../components/Navigation';
import TabletNavigation from '../../components/TabletNavigation';
import TopHeader from '../../components/TopHeader';
import Footer from '../../components/Footer';
import './index.scss';

class RootPage extends React.Component {
  componentDidMount() {
    if (!this.props.user) {
      return this.props.history.push('/sign-in');
    }
  }

  allowedRoutes(user) {
    const { routes } = this.props && this.props.route;
    return routes ? routes.filter(route => (route.auth ? route.auth(user) : true)) : [];
  }

  render() {
    const { user, innerWidth } = this.props;

    return (
      <div className="root_page">
        {innerWidth > 1380
          ? <Navigation />
          : <TabletNavigation />
        }
        <main>
          <div className="root_page__content">
            <TopHeader />
            {renderRoutes(this.allowedRoutes(user), { user })}
          </div>
          {innerWidth >= 1380 && <Footer />}
        </main>
        {innerWidth < 1380 && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  innerWidth: state.commonStore.innerWidth,
});

export default connect(mapStateToProps)(RootPage);