import * as React from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import * as classnames from 'classnames';
import { Formik } from 'formik';
import { Calendar } from 'react-modern-calendar-datepicker';
import { RoundButton } from '../../UI/RoundButton';
import { InputTextField } from '../../UI/InputTextField';
import { convertDateToString, convertStringToDate } from '../../../helpers/utils';
import { DATE_REQUEST } from '../../../helpers/common';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  start_date: Yup.object()
    .required('Start date is required'),
  company: Yup.string()
    .required('Company name is required'),
  position: Yup.string()
    .required('Position is required'),
});

class ProfileCareerForm extends React.Component {
  render() {
    const { career, onSubmit, onRemove } = this.props;
    const isEdit = !!career;

    return (
      <Formik
        enableReinitialize
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          company: isEdit ? career.company : '',
          position: isEdit ? career.position : '',
          start_date: isEdit ? convertStringToDate(career.start_date) : null,
          end_date: isEdit ? convertStringToDate(career.end_date) : null,
          isStart: true,
          showCalendar: false,
        }}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values, setValues, errors, touched, handleChange, handleSubmit,
        }) => (
          <form className="profile-career-form" onSubmit={handleSubmit}>
            <h1 className="profile-career-form__title f-32 f-500">{isEdit ? 'Edit career' : 'Add new career'}</h1>
            <div className="career-item">
              <div className="career-item__dates">
                <button
                  className={
                    classnames((values.start_date || (values.isStart && values.showCalendar))
                    && 'black', errors.start_date && touched.start_date && 'error')
                  }
                  type="button"
                  onClick={() => setValues({ ...values, isStart: true, showCalendar: true })}
                >
                  {values.start_date
                    ? `${values.start_date.day}/${values.start_date.month}/${values.start_date.year}`
                    : 'Start date'}
                </button>
                <div>-</div>
                <button
                  className={classnames((values.end_date || (!values.isStart && values.showCalendar)) && 'black')}
                  type="button"
                  onClick={() => setValues({ ...values, isStart: false, showCalendar: true })}
                >
                  {values.end_date
                    ? `${values.end_date.day}/${values.end_date.month}/${values.end_date.year}`
                    : 'End date'}
                </button>
              </div>

              <div
                className="profile-career-form__calendar"
                style={{
                  display: values.showCalendar ? 'block' : 'none',
                }}
              >
                <Calendar
                  value={values.isStart ? values.start_date : values.end_date}
                  onChange={value => {
                    const currentDate = moment(convertDateToString(value), DATE_REQUEST);
                    if (values.isStart) {
                      if (values.end_date) {
                        const endDate = moment(convertDateToString(values.end_date), DATE_REQUEST);
                        if (endDate.isBefore(currentDate)) {
                          return setValues({
                            ...values, start_date: value, end_date: null, showCalendar: false,
                          });
                        }
                        if (endDate.isSame(currentDate)) {
                          return setValues({
                            ...values, start_date: value, end_date: null, showCalendar: false,
                          });
                        }
                      }
                      return setValues({ ...values, start_date: value, showCalendar: false });
                    }
                    if (values.start_date) {
                      const startDate = moment(convertDateToString(values.start_date), DATE_REQUEST);
                      if (startDate.isAfter(currentDate)) {
                        return setValues({
                          ...values, end_date: value, start_date: null, showCalendar: false,
                        });
                      }
                      if (startDate.isSame(currentDate)) {
                        return setValues({
                          ...values, start_date: value, end_date: null, showCalendar: false,
                        });
                      }
                    }
                    return setValues({ ...values, end_date: value, showCalendar: false });
                  }}
                  shouldHighlightWeekends
                  renderFooter={() => (
                    <RoundButton
                      label="Reset"
                      type="button"
                      className="profile-career-form__calendar-reset"
                      onClick={() => {
                        values.isStart
                          ? setValues({ ...values, start_date: null, showCalendar: false })
                          : setValues({ ...values, end_date: null, showCalendar: false });
                      }}
                    />
                  )}
                />
              </div>

              <InputTextField
                label="Company"
                name="company"
                onChange={handleChange}
                value={values.company}
                error={errors.company && touched.company && errors.company}
                className="profile-career-form__field"
              />

              <InputTextField
                label="Position"
                onChange={handleChange}
                value={values.position}
                error={errors.position && touched.position && errors.position}
                name="position"
                className="profile-career-form__field"
              />
            </div>

            <div className="profile-career-form__buttons">
              {isEdit && (
              <RoundButton
                label="Remove"
                type="button"
                onClick={onRemove}
                fullWidth
                className="profile-career-form__button"
              />
              )}
              <RoundButton
                label={isEdit ? 'Update' : 'Add'}
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className="profile-career-form__button"
              />
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default ProfileCareerForm;
