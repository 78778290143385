export const GET_FR_STATUS = {
  REQUEST: 'GET_FR_STATUS_REQUEST',
  SUCCESS: 'GET_FR_STATUS_SUCCESS',
  FAILURE: 'GET_FR_STATUS_FAILURE',
}

export const GET_FR_LOAN_GROUPS = {
  REQUEST: 'GET_FR_LOAN_GROUPS_REQUEST',
  SUCCESS: 'GET_FR_LOAN_GROUPS_SUCCESS',
  FAILURE: 'GET_FR_LOAN_GROUPS_FAILURE',
}

export const GET_FR_SWAP_LIST = {
  REQUEST: 'GET_FR_SWAP_LIST_REQUEST',
  SUCCESS: 'GET_FR_SWAP_LIST_SUCCESS',
  FAILURE: 'GET_FR_SWAP_LIST_FAILURE',
}

export const SET_PARTNER_COUNTS = 'SET_PARTNER_COUNTS';
export const SET_FUNDRAISING_STATUS = 'SET_FUNDRAISING_STATUS';

export const GET_CUSTOM_STRATEGY_FOUNDERS = {
  REQUEST: 'GET_CUSTOM_STRATEGY_FOUNDERS_REQUEST',
  SUCCESS: 'GET_CUSTOM_STRATEGY_FOUNDERS_SUCCESS',
  FAILURE: 'GET_CUSTOM_STRATEGY_FOUNDERS_FAILURE',
}

export const GET_CUSTOM_STRATEGY_PARTNERS = {
  REQUEST: 'GET_CUSTOM_STRATEGY_PARTNERS_REQUEST',
  SUCCESS: 'GET_CUSTOM_STRATEGY_PARTNERS_SUCCESS',
  FAILURE: 'GET_CUSTOM_STRATEGY_PARTNERS_FAILURE',
}

export const GET_HOLD_CUSTOM_PARTNERS = {
  REQUEST: 'GET_HOLD_CUSTOM_PARTNERS_REQUEST',
  SUCCESS: 'GET_HOLD_CUSTOM_PARTNERS_SUCCESS',
  FAILURE: 'GET_HOLD_CUSTOM_PARTNERS_FAILURE',
}

export const GET_RESULT_CUSTOM_PARTNERS = {
  REQUEST: 'GET_RESULT_CUSTOM_PARTNERS_REQUEST',
  SUCCESS: 'GET_RESULT_CUSTOM_PARTNERS_SUCCESS',
  FAILURE: 'GET_RESULT_CUSTOM_PARTNERS_FAILURE',
}

export const GET_CO_FOUNDERS_LIST = {
  REQUEST: 'GET_CO_FOUNDERS_LIST_REQUEST',
  SUCCESS: 'GET_CO_FOUNDERS_LIST_SUCCESS',
  FAILURE: 'GET_CO_FOUNDERS_LIST_FAILURE',
}

