import React from 'react';
import {useSelector} from 'react-redux';
import FundraisingInfo from '../../FundraisingInfo';
import NotificationBlock from '../../NotificationBlock';
import {NOTIFICATIONS} from '../../../components/Notification/list';
// import FundraisingStartFAQ from '../../../components/FAQ/FundraisingStartFAQ';
import './index.scss';

const FundraisingStartModule = () => {
  const { status } = useSelector(state => state.fundraisingStore);
  const { withdrawInfo } = useSelector(state => state.paymentStore);
  const {goal, raised, end_date} = status.data;
  const reachedGoal = raised >= goal;
  let notifications = [
    NOTIFICATIONS.loan_disbursements_starts(end_date)
  ];

  const hasExternalAccount = !!(withdrawInfo.data && withdrawInfo.data.external_accounts.length);
  reachedGoal && !hasExternalAccount && notifications.push(NOTIFICATIONS.withdrawals_only_available_to_companies());

  return (
    <div className="fundraising-start-module">
      <h1 className="f-42 f-500">Fundraising round in progress</h1>
      <NotificationBlock
        notifications={notifications}
        className="fundraising-start-module__notifications"
      />
      <FundraisingInfo className="fundraising-start-module__dashboard" />
      {/*<FundraisingStartFAQ />*/}
    </div>
  );
}

export default FundraisingStartModule;