import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Decimal from 'decimal.js-light';
import RadioButton from '../../../UI/RadioButton';
import { prettyFloatMoney } from '../../../../helpers/utils';
import IconCreditCard from '../../../../assets/icons/icon_credit_card.svg';
import IconGiroPay from '../../../../assets/icons/icon_giropay.svg';
import LogoSepa from '../../../../assets/icons/icon_sepa_logo.png';
import { getStripeCalculatedAmount } from '../../../../store/services/stripeServices';
import s from '../index.module.scss';

export const STRIPE_PAYMENT_TYPES = {
  card: 'card',
  giropay: 'giropay',
  sepa_debit: 'sepa_debit',
};

export const PaymentMethod = ({ item, amount, disabled, formikBag }) => {
  const [commission, setCommission] = useState(0);

  const { values, setValues } = formikBag;

  useEffect(() => {
    calculate(amount);
  }, [amount]);

  const calculate = () => {
    getStripeCalculatedAmount({ amount, payment_method: item.type }).then(
      res => {
        if (res && res.success) {
          setCommission(new Decimal(res.data.amount).sub(amount).toNumber());
        }
      }
    );
  };

  const getIcon = item => {
    if (item.icon) {
      return <img src={item.icon} alt={item.title} height={23} />;
    }
    switch (item.type) {
      case STRIPE_PAYMENT_TYPES.card:
        return <img src={IconCreditCard} alt={item.title} width={31} />;
      case STRIPE_PAYMENT_TYPES.giropay:
        return <img src={IconGiroPay} alt={item.title} width={31} />;
      case STRIPE_PAYMENT_TYPES.sepa_debit:
        return <img src={LogoSepa} alt={item.title} width={36} />;
      default:
        return null;
    }
  };

  return (
    <RadioButton
      key={item.type}
      className={classnames(
        s.method_item,
        disabled && "disabled",
        values.method === item.type && 'selected'
      )}
      value={item.type}
      name="method"
      checked={values.method === item.type}
      disabled={disabled}
      onChange={() =>
        setValues({
          ...values,
          method: item.type,
          commission: 0,
          hasConfirmed: false,
        })
      }
    >
      <>
        <h5 className={classnames(s.method_title, 'f-20 f-500')}>
          {getIcon(item)}
          <span>{item.title}</span>
        </h5>
        <div className={classnames(s.method_item_commission, 'f-16 f-500')}>
          {commission
            ? `Transfer fee is ${prettyFloatMoney(commission)}`
            : 'No transfer fees'}
        </div>
      </>
    </RadioButton>
  );
};
