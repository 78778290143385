import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {getMessage, getQuery} from '../../helpers/utils';
import {
  GET_ACTIVE_FOUNDERS_LIST,
  GET_ALL_FOUNDERS_LIST,
  GET_FOUNDER_DETAIL,
  GET_FOUNDER_FUNDRAISING_STATUS, GET_FOUNDER_SWAP_PARTNERS
} from '../types/founderTypes';

const getActiveFoundersListRequest = () => ({ type: GET_ACTIVE_FOUNDERS_LIST.REQUEST });
const getActiveFoundersListSuccess = payload => ({type: GET_ACTIVE_FOUNDERS_LIST.SUCCESS, payload});
const getActiveFoundersListFailure = error => ({type: GET_ACTIVE_FOUNDERS_LIST.FAILURE, error});

const getAllFoundersListRequest = () => ({ type: GET_ALL_FOUNDERS_LIST.REQUEST });
const getAllFoundersListSuccess = payload => ({type: GET_ALL_FOUNDERS_LIST.SUCCESS, payload});
const getAllFoundersListFailure = error => ({type: GET_ALL_FOUNDERS_LIST.FAILURE, error});

const getFounderDetailRequest = () => ({ type: GET_FOUNDER_DETAIL.REQUEST });
const getFounderDetailSuccess = payload => ({type: GET_FOUNDER_DETAIL.SUCCESS, payload});
const getFounderDetailFailure = error => ({type: GET_FOUNDER_DETAIL.FAILURE, error});

// TODO remove
const getFounderFundraisingStatusRequest = () => ({ type: GET_FOUNDER_FUNDRAISING_STATUS.REQUEST });
const getFounderFundraisingStatusSuccess = payload => ({type: GET_FOUNDER_FUNDRAISING_STATUS.SUCCESS, payload});
const getFounderFundraisingStatusFailure = error => ({type: GET_FOUNDER_FUNDRAISING_STATUS.FAILURE, error});
// TODO remove
const getFounderSwapPartnersRequest = () => ({ type: GET_FOUNDER_SWAP_PARTNERS.REQUEST });
const getFounderSwapPartnersSuccess = payload => ({type: GET_FOUNDER_SWAP_PARTNERS.SUCCESS, payload});
const getFounderSwapPartnersFailure = error => ({type: GET_FOUNDER_SWAP_PARTNERS.FAILURE, error});

export const getActiveFoundersList = params => {
  return dispatch => {
    dispatch(getActiveFoundersListRequest());
    return axios.get(Pathes.Founders.activeList + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getActiveFoundersListSuccess(data));
          return { data, success: true };
        }
        throw new Error(message)
      }).catch(e => dispatch(getActiveFoundersListFailure(e.message)));
  };
}

export const getAllFoundersList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getAllFoundersListRequest());
    return axios.get(Pathes.Founders.allList + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().founderStore.allList.data;
          if (!isNext || !prevData) {
            dispatch(getAllFoundersListSuccess(data));
            return { data, success: true, message};
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }

          dispatch(getAllFoundersListSuccess(updatedData));
          return {...updatedData, success: true, message};
        }
        throw new Error(message)
      }).catch(e => dispatch(getAllFoundersListFailure(e.message)));
  };
}

// TODO remove
export const getFounderDetail = id => {
  return dispatch => {
    dispatch(getFounderDetailRequest());
    return axios.get(Pathes.Founders.detail(id)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFounderDetailSuccess(data));
          return { data, success: true };
        }
        throw new Error(message)
      }).catch(e => dispatch(getFounderDetailFailure(e.message)));
  };
}