import React from 'react';
import FoundersActive from '../../containers/FoundersModule/active';
import FoundersAll from '../../containers/FoundersModule/all';
import './index.scss';

const FoundersPage = () => {
  return (
    <div className="founders-page">
      <div className="container-inner">
        <h1 className="founders-page__title f-42 f-500">Founders</h1>
        <FoundersActive />
        <FoundersAll />
      </div>
    </div>
  );
};

export default FoundersPage;