import React from 'react';
import {renderRoutes} from 'react-router-config';
import {NavLink} from 'react-router-dom';
import './index.scss';

const TABS = [
  {
    label: 'Next repayment',
    path: '/payments/next-payments'
  },
  {
    label: 'Prepay loans',
    path: '/payments/prepay-loans'
  },
  {
    label: 'Add funds',
    path: '/payments/add-funds'
  },
  {
    label: 'Withdraw',
    path: '/payments/withdraw'
  },
];

const PaymentsPage = ({ route, user }) => (
  <div className="payments-page">
    <div className="container-inner">
      <h1 className="payments-page__title f-500 f-42">Funds</h1>
      <div className="payments-page__links">
        {TABS.map(tab => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className="payments-page__link"
            activeClassName="payments-page__link-selected"
            exact
          >
            {tab.label}
          </NavLink>
        ))}
      </div>
      {renderRoutes(route.routes, { user })}
    </div>
  </div>
);

export default PaymentsPage;