import React, {useState} from 'react';
import * as classnames from 'classnames';
import {Link} from 'react-router-dom';
import Avatar from '../../components/UI/Avatar';
import {getFullname, getNameInitials} from '../../helpers/utils';
import {SWAP_STATUSES} from '../../helpers/constants';
import {selectSwapPartner} from '../../store/services/fundraisingServices';
import './index.scss'

const SwapPartner = ({founder, setPartnerCounts, onSelect, active, disableSelectedBy, className}) => {
  const [status, setStatus] = useState(founder.status);
  const [selectedBy, setSelectedBy] = useState(founder.selected_count);
  const fullname = getFullname(founder.first_name, founder.last_name);
  const showCheckbox = status === SWAP_STATUSES.not_selected || status === SWAP_STATUSES.selected;

  const onChange = () => {
    if (status !== SWAP_STATUSES.partner) {
      const payload = {
        founder_id: founder.id,
        action: status === SWAP_STATUSES.selected ? SWAP_STATUSES.not_selected : SWAP_STATUSES.selected
      }

      if (onSelect && status === SWAP_STATUSES.not_selected) {
        return onSelect({payload, setStatus, fullname, selectSwapPartner, setSelectedBy});
      }

      selectSwapPartner(payload).then(res => {
        if (res && res.success) {
          setStatus(res.data.status);
          setSelectedBy(res.data.selected_count);
          setPartnerCounts && setPartnerCounts(res.data.counts);
        }
      })
    }
  }

  return (
    <Link
      to={`/founders/${founder.id}`}
      className={classnames(
        "swap-partner",
        (status === SWAP_STATUSES.not_selected || !status) && "white",
        className
      )}
    >
      {showCheckbox && (
        <label className="p-checkbox" onClick={e => e.stopPropagation()}>
          <input type="checkbox" className="p-checkbox__input" checked={status === SWAP_STATUSES.selected} onChange={onChange} />
          <span className="p-checkbox__checkmark" />
        </label>
      )}
      <div className="swap-partner__avatar">
        <Avatar
          src={founder.photo && founder.photo.file}
          nameInitials={getNameInitials(founder.first_name, founder.last_name)}
          alt={fullname}
          size={160}
          active={active}
        />
        {status === SWAP_STATUSES.partner && <div className="swap-partner__partner f-12 f-500">Partner</div>}
      </div>
      <h6 className="swap-partner__name f-18 f-500">{fullname}</h6>
      <p className="swap-partner__company f-14 tl">{founder.company}</p>
      {!!founder.themes.length && (
        <ul className="swap-partner__themes f-14">
          {founder.themes.map(theme => (
            <li key={theme.id} className="swap-partner__theme tl">{theme.title}</li>
          ))}
        </ul>
      )}

      {!!selectedBy && !disableSelectedBy && (
        <div className="swap-partner__selected f-14">
          <div className="swap-partner__selected-icon">
            <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2.5" cy="2.5" r="2.5" fill="black"/>
            </svg>
            <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2.5" cy="2.5" r="2.5" fill="black"/>
            </svg>
            <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2.5" cy="2.5" r="2.5" fill="black"/>
            </svg>
          </div>
          <span>selected by {selectedBy} founder{selectedBy !== 1 && 's'}</span>
        </div>
      )}
    </Link>
  );
};

export default SwapPartner;