import React from 'react';
import WithdrawForm from '../../components/Forms/WithdrawForm';
import {connect} from 'react-redux';
import Preloader from '../../components/UI/Preloader';
import {createWithdrawRequest, getWithdrawInfo} from '../../store/actions/paymentActions';
import {RoundLink} from '../../components/UI/RoundLink';
import {getBalance} from '../../store/actions/dashboardActions';
import {getMissingProfileDetails} from '../../store/actions/profileActions';
import {ROLES} from '../../helpers/constants';
import WithdrawHistory from '../../containers/WithdrawHistory';
import './index.scss';

class PaymentsWithdrawPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyUpdater: new Date().toISOString() // Used to force update Withdrawals history
    }
  }

  componentDidMount() {
    this.props.getWithdrawInfo();
    this.props.getMissingProfileDetails();
  }

  onSubmit = (values, formikBag) => {
    const payload = {
      account: values.account.id,
      amount: values.amount
    }

    this.props.createWithdrawRequest(payload).then(res => {
      if (res && res.success) {
        this.props.getBalance();
        this.setState(prevProps => ({...prevProps, historyUpdater: new Date().toISOString()})) // Force update Withdrawals History
        formikBag.resetForm();
        formikBag.setValues({ account: values.account, amount: ''});
      }
    })
  };

  render() {
    const {user} = this.props;
    const {data, loading} = this.props.withdrawInfo;
    const hasExternalAcc = !!data?.external_accounts?.length;

    return (
      <div className="payments-withdraw-page">
        {loading
          ? <Preloader className="payments-withdraw-page__preloader" style={{ marginTop: '20px', height: '40vh' }} />
          : hasExternalAcc
            ? (
              <WithdrawForm
                balance={data && data.cash}
                accounts={data && data.external_accounts}
                onSubmit={this.onSubmit}
              >
                {user.role === ROLES.founder && (
                  <WithdrawHistory
                    resetTime={this.state.historyUpdater}
                    className="payments-withdraw-page__history"
                  />
                )}
              </WithdrawForm>
            ) : (
              <div className="payments-withdraw-page__empty">
                <p className="payments-withdraw-page__empty-title f-26 f-500">No bank account details</p>
                <p className="payments-withdraw-page__empty-desc f-16">
                  Withdrawals are only available to companies with valid bank accounts (IBAN for EU-based companies).
                  To provide bank account details, please transfer at least <b>€5</b> from a valid bank account of the
                  company or send a bank account statement file to your Scramble manager <a href="mailto:irina@scrambleup.com">irina@scrambleup.com</a>.
                </p>
                <RoundLink
                  label="Add funds"
                  path="/payments/add-funds"
                  className="payments-withdraw-page__empty-link"
                />
              </div>
            )}
            {/*<FundsWithdrawFAQ />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  withdrawInfo: state.paymentStore.withdrawInfo,
})

const mapDispatchToProps = dispatch => ({
  getWithdrawInfo: () => dispatch(getWithdrawInfo()),
  createWithdrawRequest: payload => dispatch(createWithdrawRequest(payload)),
  getBalance: () => dispatch(getBalance()),
  getMissingProfileDetails: () => dispatch(getMissingProfileDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsWithdrawPage);
