import * as React from 'react';
import {logoutUser} from '../../store/actions/userActions';
import {connect} from 'react-redux';
import {Header} from '../../containers/Header';
import Footer from '../../components/Footer';
import './index.scss';

class BasicLayout extends React.Component {
  render() {
    const { user, logout, children } = this.props;
    return (
      <div className="basic-layout">
        <Header user={user} logout={logout} />
        <main>
          {children}
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicLayout);