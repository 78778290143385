import React from 'react';
import moment from 'moment';
import {prettyFloatMoney} from '../../helpers/utils';
import {DATE_FORMAT_DD_MMM} from '../../helpers/common';
import './index.scss';

const DashPaymentInfo = ({ payment }) => (
  <div className="dash-payment-info">
    <div className="dash-payment-info__block">
      <p className="f-16">Next repayment</p>
      <p className="dash-payment-info__pay f-42 f-500 tl">{prettyFloatMoney(payment && payment.next_payment_amount)}</p>
    </div>

    <div className="dash-payment-info__block">
      <p className="f-16">Due date</p>
      <p className="dash-payment-info__date f-26 f-500 tl">{payment && payment.next_payment_date ? moment(payment.next_payment_date).format(DATE_FORMAT_DD_MMM).replace('.', '') : '-'}</p>
    </div>
  </div>
)

export default DashPaymentInfo;