import * as React from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import * as classnames from 'classnames';
import {Formik} from 'formik';
import {InputTextField} from '../../UI/InputTextField';
import CountrySelectField from '../../UI/CountrySelectField';
import {RoundButton} from '../../UI/RoundButton';
import TextareaField from '../../UI/TextareaField';
import SelectField from '../../UI/SelectField';
import CheckboxList from '../../../components/CheckboxList';
import {getStartupSectors} from '../../../store/services/userServices';
import {PhoneNumberField} from '../../UI/PhoneNumberField';
import {DATE_FORMAT_YYYY, WEBSITE_REGEX} from '../../../helpers/common';
import './index.scss';

const FOUNDATION_YEAR_OPTIONS = new Array(Number(moment().format(DATE_FORMAT_YYYY)) - 1989).fill(0).reduce((options, item, idx) => {
  const year = 1990 + idx;
  options.push({value: year, label: year});
  return options;
}, []);

const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .max(100, 'Company name exceeds limit of 100')
    .required('Company name is required'),
  full_name: Yup.string()
    .required('Full company name is required'),
  address: Yup.string()
    .max(255, 'Address exceeds limit of 255')
    .required('Address is required'),
  email: Yup.string()
    .email('Email is not valid')
    .required('Email is required'),
  registration_number: Yup.string()
    .required('Registration number is required'),
  country: Yup.string()
    .required('Country is required'),
  description: Yup.string()
    .max(1000, 'Description exceeds limit of 1000'),
  site: Yup.string()
    .matches(
      WEBSITE_REGEX,
      'Enter correct url'
    ),
  phone_number: Yup.number()
    .required('Phone number is required'),
  is_valid_phone: Yup.bool()
    .oneOf([true], "Invalid phone number")
    .required()
});

class ProfileStartupForm extends React.Component {
  state = {
    sectorsList: [],
  }

  componentDidMount() {
    getStartupSectors()
      .then(res => res && res.success && this.setState(prevState => ({
        ...prevState,
        sectorsList: res.data,
      })))
  }

  render() {
    const {sectorsList} = this.state;
    const {company, location, onSubmit, onCancel} = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          logo: null,
          logoURL: company.image && company.image.file,
          name: company && company.name || '',
          full_name: company && company.full_name || '',
          address: company && company.address || '',
          email: company && company.email || '',
          registration_number: company && company.registration_number || '',
          country: (company && company.country) || (location && location.country) || '',
          country_code: (company && company.country_code) || (location && location.countryCode) || 'RU',
          phone_number: (company && company.phone_number) || '',
          is_valid_phone: true,
          sectors: (company && company.sectors) || [],
          foundation_year: company.foundation_date ? moment(company.foundation_date).format(DATE_FORMAT_YYYY) : '',
          site: (company && company.site) || '',
          description: (company && company.description) || '',
          promoImage: null,
          promoImageURL: company.promo && company.promo.image && company.promo.image.file,
        }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({values, errors, touched, setValues, handleChange, handleSubmit, setFieldValue}) => (
          <form className="profile-startup-form" onSubmit={handleSubmit}>
            <h1 className="profile-startup-form__title f-32 f-500">Edit Startup</h1>
            <div className="profile-startup-form__logo">
              <div className={classnames("profile-startup-form__logo-title", values.logo ? "f-12" : " f-16")}>Company Logo</div>
              {values.logoURL && (
                <div className="profile-startup-form__logo-preview">
                  <img src={values.logoURL} alt="Image preview" />
                </div>
              )}
              <label
                htmlFor="logo"
                className={classnames(
                  "profile-startup-form__logo-upload f-14",
                  (!values.logo && !values.logoURL) && "button"
                )}
              >
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  onChange={e => {
                    const file = e.target.files[0];
                    setValues({
                      ...values,
                      logo: file,
                      logoURL: URL.createObjectURL(file)
                    })
                  }}
                />
                Select logo
              </label>
              {(values.logo || values.logoURL) && (
                <button
                  type="button"
                  onClick={() => setValues({...values, logo: null, logoURL: null})}
                  className="profile-startup-form__logo-remove f-14"
                >
                  Remove
                </button>
              )}
            </div>

            <InputTextField
              label="Company name *"
              name="name"
              value={values.name}
              onChange={handleChange}
              onClear={() => setFieldValue("name", '')}
              error={errors.name && touched.name && errors.name}
              className="profile-startup-form__field"
            />
            <InputTextField
              label="Full company name *"
              name="full_name"
              value={values.full_name}
              onChange={handleChange}
              onClear={() => setFieldValue("full_name", '')}
              error={errors.full_name && touched.full_name && errors.full_name}
              className="profile-startup-form__field"
            />
            <InputTextField
              label="Company registration number *"
              name="registration_number"
              value={values.registration_number}
              onChange={handleChange}
              onClear={() => setFieldValue("registration_number", '')}
              error={errors.registration_number && touched.registration_number && errors.registration_number}
              className="profile-startup-form__field"
            />
            <InputTextField
              label="Company email *"
              name="email"
              value={values.email}
              onChange={handleChange}
              onClear={() => setFieldValue("email", '')}
              error={errors.email && touched.email && errors.email}
              className="profile-startup-form__field"
            />
            <PhoneNumberField
              label="Company phone number *"
              name="phone_number"
              value={values.phone_number}
              countryCode={values.country_code}
              onChange={(phone_number) => setFieldValue('phone_number', phone_number)}
              onClear={() => setFieldValue("phone_number", '')}
              error={
                (errors.phone_number || errors.is_valid_phone)
                && (touched.phone_number || touched.is_valid_phone)
                && (errors.phone_number || errors.is_valid_phone)
              }
              isValid={values.is_valid_phone}
              formikBag={{setFieldValue, touched}}
              containerID="companyPhone"
              className="profile-startup-form__field"
            />
            <InputTextField
              label="Company address *"
              name="address"
              value={values.address}
              onChange={handleChange}
              onClear={() => setFieldValue("address", '')}
              error={errors.address && touched.address && errors.address}
              className="profile-startup-form__field"
            />
            <CountrySelectField
              label="Country *"
              name="country"
              value={values.country_code}
              onChange={(country, code) => {
                setFieldValue('country', country);
                setFieldValue('country_code', code);
              }}
              error={errors.country && touched.country && errors.country}
              className="profile-startup-form__field"
            />
            <InputTextField
              label="Website"
              name="site"
              value={values.site}
              onChange={handleChange}
              onClear={() => setFieldValue("site", '')}
              error={errors.site && touched.site && errors.site}
              className="profile-startup-form__field"
            />
            <TextareaField
              label="Description"
              name="description"
              minRows={4}
              value={values.description}
              onChange={handleChange}
              limit={1000}
              error={errors.description && touched.description && errors.description}
              className="profile-personal-form__field"
            />
            <SelectField
              label="Foundation date"
              name='foundation_year'
              value={values.foundation_year}
              onChange={handleChange}
              error={errors.foundation_year && touched.foundation_year && errors.foundation_year}
              className="profile-personal-form__field"
              options={FOUNDATION_YEAR_OPTIONS}
            />
            <CheckboxList
              label="Sectors"
              list={sectorsList}
              selected={values.sectors.map(item => item.id)}
              className="profile-personal-form__field"
              onChange={value => {
                const selections = values.sectors.map(item => item.id);
                selections.includes(value.id)
                  ? setFieldValue('sectors', values.sectors.filter(item => item.id !== value.id))
                  : setFieldValue('sectors', [...values.sectors, value])
              }}
            />

            <div className="profile-startup-form__promo">
              <div className={classnames("profile-startup-form__promo-title", values.logo ? "f-12" : " f-16")}>Company promo</div>
              {values.promoImageURL && (
                <div className="profile-startup-form__promo-preview">
                  <img src={values.promoImageURL} alt="Promo preview" />
                </div>
              )}
              <label
                htmlFor="promoImage"
                className={classnames(
                  "profile-startup-form__promo-upload f-14",
                  (!values.promoImage && !values.promoImageURL) && "button"
                )}
              >
                <input
                  type="file"
                  name="promoImage"
                  id="promoImage"
                  onChange={e => {
                    const file = e.target.files[0];
                    setValues({
                      ...values,
                      promoImage: file,
                      promoImageURL: URL.createObjectURL(file)
                    })
                  }}
                />
                Select image
              </label>
              {(values.promoImage || values.promoImageURL) && (
                <button type="button" onClick={() => setValues({...values, promoImage: null, promoImageURL: null})} className="profile-startup-form__promo-remove f-14">
                  Remove
                </button>
              )}
            </div>
            <div className="profile-startup-form__buttons">
              {onCancel && (
                <RoundButton
                  label="Cancel"
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  className="profile-startup-form__button"
                />
              )}
              <RoundButton
                label="Save"
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                className="profile-startup-form__button"
              />
            </div>
          </form>
        )}
      </Formik>
    )
  }
}

export default ProfileStartupForm;