import React from 'react';
import * as classnames from 'classnames';
import {getLoanName, prettyFloatMoney} from '../../helpers/utils';
import {RoundButton} from '../../components/UI/RoundButton';
import {useSelector} from 'react-redux';
import {ROLES} from '../../helpers/constants';

export const FR_Summary = ({status, theme, onEdit}) => {
  const user = useSelector(state => state.userStore.user);
  return (
    <div className={classnames("fr-summary", onEdit && "edit", theme)}>
      <div className="fr-summary__goal">
        <div className="fr-summary__title f-16 f-400">Fundraising goal</div>
        <div className="fr-summary__value f-26 tl f-500">{status.goal === null ? '—' : prettyFloatMoney(status.goal)}</div>
      </div>
      <div className="fr-summary__loan">
        <div className="fr-summary__title f-16 f-400">Loan offerings</div>
        <div className="fr-summary__value f-26 tl f-500">{getLoanName(status.loan_groups)}</div>
      </div>
      {user.role === ROLES.founder && onEdit && !status.is_active && status.step < 4 && (
        <RoundButton
          label="Edit"
          type="button"
          onClick={onEdit}
          className="fr-summary__edit"
          fullWidth
        />
      )}
    </div>
  );
};

export default FR_Summary;