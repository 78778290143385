import React from 'react';
import {Formik} from 'formik';
import {checkForValidFile} from '../../../helpers/utils';
import {PHOTO_ALLOWED_FORMATS} from '../../../helpers/common';
import AvatarEdit from '../../../components/AvatarEdit';
import Avatar from '../../../components/UI/Avatar';
import {RoundButton} from '../../../components/UI/RoundButton';
import './index.scss';

const ProfileAvatarForm = ({ photo, fullname, nameInitials, onSubmit, onRemove }) => {
  return (
    <Formik
      enableReinitialize
      onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      initialValues={{
        file: null,
        photoSrc: photo && photo.file,
        editorRef: null,
      }}
    >
      {({ values, handleSubmit, setFieldError, setFieldValue }) => (
        <form className="profile-avatar-form" onSubmit={handleSubmit}>
          <h1 className="profile-avatar-form__photo f-26 f-500">Update photo</h1>
          <div className="profile-avatar-form__content">
            {values.file ? (
              <AvatarEdit
                src={values.file}
                name="file"
                size={260}
                setFieldValue={setFieldValue}
                className="profile-avatar-form__editor"
              />
            ) : (
              <Avatar
                size={260}
                src={values.photoSrc}
                alt={fullname}
                nameInitials={nameInitials}
                className="profile-avatar-form__avatar"
              />
            )}
          </div>

          <div className="profile-avatar-form__controls">
            <label
              htmlFor="file"
              className="profile-avatar-form__upload f-16 f-500"
            >
              Change photo
            </label>
            <input className="profile-avatar-form__input" type="file" name="file" id="file" onChange={e => {
              const file = e.target.files[0];
              const { isValid, type, size } = checkForValidFile(file, PHOTO_ALLOWED_FORMATS, 12582912);
              !isValid && size && setFieldError('file', 'File size is too big');
              !isValid && type && setFieldError('file', 'Incorrect file type. Upload your photo in jpg or png format.');
              isValid && setFieldValue('file', file)
            }} />

            {photo && (
              <RoundButton
                label="Remove photo"
                className="profile-avatar-form__remove"
                type="button"
                onClick={onRemove}
                fullWidth
              />
            )}

            {values.file && (
              <RoundButton
                label="Save"
                fillBackground
                fullWidth
                type="submit"
                className="profile-avatar-form__save"
                onSubmit={handleSubmit}
              />
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProfileAvatarForm;