import React, {Component} from 'react';
import {connect} from 'react-redux';
import FundraisingInfo from '../../FundraisingInfo';
import NotificationBlock from '../../NotificationBlock';
import {ROLES} from '../../../helpers/constants';
import {prettyFloatMoney} from '../../../helpers/utils';
import {getNextPayment} from '../../../store/actions/paymentActions';
import {RoundButton} from '../../../components/UI/RoundButton';
import {RoundLink} from '../../../components/UI/RoundLink';
import {NOTIFICATIONS} from '../../../components/Notification/list';
import {APP_LINKS} from "../../../helpers/links";
import './index.scss';

// import ResultCustomFoundersList from './custom';

class FundraisingResultsModule extends Component {
  componentDidMount() {
    this.props.getNextPayment();
  }

  render() {
    const {status, setNewFundraising, nextPayment, user} = this.props;
    const {raised, goal, disbursement_fee, cash_received} = status;
    const notifications = [];
    const isRaisedFunds = raised >= goal;
    const hasLoans = raised > 0

    hasLoans
      ? notifications.push(NOTIFICATIONS.your_loan_successfully_completed(raised))
      : notifications.push(NOTIFICATIONS.not_enough_investor_to_fund());

    hasLoans && (notifications.push(NOTIFICATIONS.origination_fee_charged(disbursement_fee)));

    const firstPayment = nextPayment.data && !!Number(nextPayment.data.total_amount) && prettyFloatMoney(nextPayment.data.total_amount);
    hasLoans && firstPayment && notifications.push(NOTIFICATIONS.next_montly_repayment(firstPayment, nextPayment.data.deadline));

    return (
      <div className="fundraising-result-module">
        {!hasLoans
          ? <h1 className="f-42 f-500">This fundraising is over</h1>
          : isRaisedFunds
            ? <h1 className="f-42 f-500">Congrats! You've achieved your goal. <br/> We've transferred {prettyFloatMoney(cash_received)} to your account.</h1>
            : <h1 className="f-42 f-500">Congrats! You've got your loans.</h1>
        }
        <NotificationBlock
          notifications={notifications}
          className="fundraising-result-module__notifications"
        />
        {user.role === ROLES.founder && (
          <div className="fundraising-result-module__buttons">
            {hasLoans && (
              <RoundLink
                label="Withdraw funds"
                path={APP_LINKS.withdraw}
                className="fundraising-result-module__button"
                fillBackground
              />
            )}
            <RoundButton
              label="Set new fundraising goal"
              className="fundraising-result-module__button"
              type="button"
              fillBackground={!hasLoans}
              onClick={setNewFundraising}
            />
          </div>
        )}
        <h1 className="fundraising-result-module__details f-26 f-500">Fundraising details</h1>
        <FundraisingInfo
          disableSummary
          className="fundraising-result-module__dashboard"
        />
        {/*<FundraisingResultFAQ />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  status: state.fundraisingStore.status.data,
  nextPayment: state.paymentStore.nextPayment,
})

const mapDispatchToProps = dispatch => ({
  getNextPayment: () => dispatch(getNextPayment()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FundraisingResultsModule);