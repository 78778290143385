import React, { useState } from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Dropdown = ({
  title,
  description,
  className,
  defaultShow,
  disableToggle,
  label,
  children,
  ...props
}) => {
  const [show, setShow] = useState(!!defaultShow);
  return (
    <div
      className={classnames(
        'dropdown',
        disableToggle && 'disabled',
        show && 'active',
        className
      )}
      {...props}
    >
      <div
        className="dropdown__header"
        onClick={() => !disableToggle && setShow(!show)}
      >
        {label || (
          <span className="f-26 f-500">{title || 'Founders batch'}</span>
        )}

        {!disableToggle && (
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ minWidth: '14px' }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.78144 7.68L13.6766 1.89333C14.1078 1.44 14.1078 0.746667 13.6766 0.32C13.2455 -0.106667 12.518 -0.106667 12.0868 0.32L7 5.33333L1.91317 0.32C1.45509 -0.106667 0.754491 -0.106667 0.323353 0.32C-0.107784 0.746667 -0.107784 1.44 0.323353 1.89333L6.19162 7.68C6.6497 8.10667 7.3503 8.10667 7.78144 7.68Z"
              fill="black"
            />
          </svg>
        )}
      </div>
      {description && (
        <p className="dropdown__description f-16 f-400">{description}</p>
      )}
      <div className="dropdown__content">{children}</div>
    </div>
  );
};

export default Dropdown;
