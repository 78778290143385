import React from 'react';
import {prettyFloatMoney} from '../../helpers/utils';
import './index.scss';

const DashBalanceInfo = ({ balance }) => {
  return (
    <div className="dash-balance-info">
      <div className="dash-balance-info__block">
        <p className="f-16">Your cash account</p>
        <p className="dash-balance-info__cash f-42 f-500 tl">{prettyFloatMoney(balance && balance.available_cash)}</p>
      </div>

      <div className="dash-balance-info__block">
        <p className="f-16">Outstanding loan</p>
        <p className="dash-balance-info__loan f-26 f-500 tl">{prettyFloatMoney(balance && balance.outstanding)}</p>
      </div>
    </div>
  );
};

export default DashBalanceInfo;