import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const CheckboxList = ({label, name, list = [], selected = [],  onChange, className}) => (
  <div className={classnames("checkbox-list", className)}>
    {label && <div className="checkbox-list__title f-16">{label}</div>}
    {list.map(item => (
      <label className="s-checkbox" key={item.id}>
        {item.name}
        <input
          name={name}
          type="checkbox"
          checked={selected.includes(item.id)}
          className="s-checkbox__input"
          onChange={() => onChange(item)}
        />
        <span className="s-checkbox__checkmark" />
      </label>
    ))}
  </div>
);

export default CheckboxList;